import React, { useEffect, useState } from 'react'
import { languagesArr } from '../GlobalConst'
import { postRequest } from '../../ApiFunctions.tsx/post'
import { deleteRequest } from '../../ApiFunctions.tsx/delete'
import { useParams } from 'react-router-dom'

const BoilerPlateComponent = (props: { data: any }) => {
    const {id} = useParams()
    const [expand, setExpand] = useState(false)
    const [language, setLanguage] = useState<any>({ id: 0, name: '' })
    // {
    //     "id": "5420288693248",
    //     "language_id": 54,
    //     "code": "I2luY2x1ZGUgPGlvc3RyZWFtPgp1c2luZyBuYW1lc3BhY2Ugc3RkOwoKdm9pZCBwcmludElucHV0KHN0cmluZyBzKXsKCn0KCmludCBtYWluKCl7CiAgICBzdHJpbmcgczsKICAgIGNpbj4+czsKICAgIHByaW50SW5wdXQocyk7Cn0=",
    //     "problemId": "23286027264"
    // }

    const deleteBoilerPlate = (scid: any) => {
        window.confirm('are you sure you want to delete this boilerplate?')&& deleteRequest(`${process.env.REACT_APP_API}problem/${id}/deleteboilerplate/${scid}`,(data)=>{
            window.location.reload()
        },(err)=>{
            console.log(err)
        })
    }
    useEffect(() => {
        languagesArr.map((lang: any) => lang.id === props.data.language_id && setLanguage(lang))
    }, [])
    return (
        <div onClick={() => setExpand(!expand)} className='cursor-pointer w-full bg-gradient-to-b from-[#ebebeb00] to-[#ebebeb] rounded-lg h-auto px-4 py-3'>
            {!expand ? (
                <div className='text-darkBlue font-montserrat text-xl font-bold flex justify-between items-center'>{language.name.split(' (')[0]}
                    <div onClick={() => deleteBoilerPlate(props.data.id)} className='h-fit w-fit cursor-pointer '>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M15.1464 4.35355L15.2929 4.5H15.5H18.5V5.5H5.5V4.5H8.5H8.70711L8.85355 4.35355L9.70711 3.5H14.2929L15.1464 4.35355ZM8 20.5C7.17614 20.5 6.5 19.8239 6.5 19V7.5H17.5V19C17.5 19.8239 16.8239 20.5 16 20.5H8Z"
                                stroke="#FF8E96" fill="#FF8E96" />
                        </svg>
                    </div>
                </div>
            ) : (
                <div className='w-full  rounded-md p-2'>
                    <div className='text-darkBlue font-montserrat text-2xl font-semibold flex justify-between items-center'>{language.name.split(' (')[0]}
                        <div onClick={() => deleteBoilerPlate(props.data.id)} className='h-fit w-fit cursor-pointer '>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M15.1464 4.35355L15.2929 4.5H15.5H18.5V5.5H5.5V4.5H8.5H8.70711L8.85355 4.35355L9.70711 3.5H14.2929L15.1464 4.35355ZM8 20.5C7.17614 20.5 6.5 19.8239 6.5 19V7.5H17.5V19C17.5 19.8239 16.8239 20.5 16 20.5H8Z"
                                    stroke="#FF8E96" fill="#FF8E96" />
                            </svg>
                        </div>
                    </div>
                    <div className='text-darkBlue text-lg font-montserrat font-semibold mt-3'>
                        code
                    </div>
                    <div className='text-textBlack text-base font-manrope  '>
                        {atob(props.data.code)}
                    </div>
                </div>
            )}
        </div>
    )
}

export default BoilerPlateComponent
