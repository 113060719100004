import React from 'react'

const LinearGradientList = (props: { children: any }) => {
    return (
        <div className='text-textGrey cursor-pointer w-full rounded-lg p-4 flex bg-gradient-to-b from-[#ebebeb00] to-[#ebebeb]'>
            {props.children}
        </div>
    )
}

export default LinearGradientList
