import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getRequest } from '../ApiFunctions.tsx/get'
import LinearGradientList from '../components/examPortal/LinearGradientList'
import { PrimaryButton, SecondaryButtons } from '../components/Buttons'
import { deleteRequest } from '../ApiFunctions.tsx/delete'

const TestCaseView = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [testcases, setTestcases] = useState<any[]>([])

    useEffect(() => {
        const onSuccess = (data: any) => {
            setTestcases(data)
            console.log(data)
        }
        const onFailure = (err: any) => {
            console.log(err)
        }

        getRequest(`${process.env.REACT_APP_API}problem/${id}/testcases`, onSuccess, onFailure)
    }, [id])


    const deleteTestCase=(testid:any)=>{
        const onSuccess=(data:any)=>{
            window.alert('testcase deleted successfully')
            // navigate('/')
            window.location.reload()
        }

        const onFailure=(err:any)=>{
            console.log(err)
        }

        return deleteRequest(`${process.env.REACT_APP_API}problem/${id}/deletetestcase/${testid}`, onSuccess, onFailure)
    }

    return (
        <div className={`bg-gradient-to-br from-white to-whitesky min-h-screen w-full h-auto pt-28 px-24 pb-10`}>
            <div className='bg-white rounded-lg flex flex-col w-full min-h-[70vh] h-auto py-4 px-16'>
                <div className='text-darkBlue text-2xl font-manrope font-semibold p-5'>
                    Test Cases
                </div>
                <div className='mt-10 flex flex-col gap-3'>
                    {testcases.length > 0 ? testcases.map((testCase: any, i: number) => {
                        return <div key={`${testCase.input}${Math.floor(Math.random() * 1000)}`}>
                            <LinearGradientList>
                                <div className='w-full flex justify-between'>
                                    <span>
                                        Testcase {i + 1} : {atob(testCase.input)}
                                    </span>
                                    <div onClick={()=>deleteTestCase(testCase.id)} className='h-fit w-fit cursor-pointer '>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M15.1464 4.35355L15.2929 4.5H15.5H18.5V5.5H5.5V4.5H8.5H8.70711L8.85355 4.35355L9.70711 3.5H14.2929L15.1464 4.35355ZM8 20.5C7.17614 20.5 6.5 19.8239 6.5 19V7.5H17.5V19C17.5 19.8239 16.8239 20.5 16 20.5H8Z"
                                                stroke="#FF8E96" fill="#FF8E96" />
                                        </svg>
                                    </div>
                                </div>
                            </LinearGradientList>
                        </div>
                    }) : (
                        <div className='text-inputBorder text-xl font-montserrat w-full h-full flex justify-center items-center'>
                            <span>
                                No Testcases have been added till now
                            </span>
                        </div>
                    )}
                </div>

                <div className='w-min mx-auto mt-auto flex gap-5'>
                    <SecondaryButtons onClick={() => { navigate(`/viewcq/${id}`) }}>
                        Done
                    </SecondaryButtons>
                    <PrimaryButton onClick={() => { navigate('/') }}>
                        Home
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}

export default TestCaseView
