import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getRequest } from '../ApiFunctions.tsx/get'
import { PrimaryButton, SecondaryButtons } from '../components/Buttons'
import { postRequest } from '../ApiFunctions.tsx/post'
import * as XLSX from 'xlsx';
import { deleteRequest } from '../ApiFunctions.tsx/delete'
import { useUserData } from '../components/useUserData'

const AddCandidates = () => {
  const { id } = useParams()
  const {designation}=useUserData()
  const navigate = useNavigate()
  const [scheduledata, setSchedule] = useState<any>()
  const [email, setEmail] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [excelData, setExcelData] = useState<any[]>([])
  const [userId,setUserId]=useState<any>()

  const submitMultiple = (excel:any) => {
    console.log(excel)
    const data2={
      scheduleId:id,
      users:excel
    }
    console.log(data2)
    postRequest(`${process.env.REACT_APP_API}exam/schedule/addcandidates`, data2, (data) => {
      console.log(data)
      // postRequest(`${process.env.REACT_APP_API}exam/schedule/sendmail`, {
      //   scheduleId: id
      // }, (data) => {
        window.alert('credentials sent to mail successfully')
        navigate('/')
      // }, (err) => {
      //   console.log(err)
      // })
    }, (err) => {
      console.log(err)
    })
  }
  

  const uploadMultiple = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.id = `fakeinput`;
  
    
    inputElement.addEventListener('change', function (e: any) {
      // console.log('File selected:', this.files[0].name);
      const file = e.target.files[0];
      if (file) {
        readExcel(file);


      }
      // Add your custom logic here after the file is selected
    });
    inputElement.click() 

  }

  const readExcel = async(file: any) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      // Assuming you have only one sheet
      const sheetName = workbook.SheetNames[0];
      const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      let arr: any[] = []
      excelData.map((data: any, i: number) => {
        let choices: any[] = []

        if (i === excelData.length - 1) {

          if (data.type) {
            if (data.Choice_1) {
              choices.push(data.Choice_1)
            }
            if (data.Choice_2) {
              choices.push(data.Choice_2)
            }
            if (data.Choice_3) {
              choices.push(data.Choice_3)
            }
            if (data.Choice_4) {
              choices.push(data.Choice_4)
            }
            if (data.Choice_5) {
              choices.push(data.Choice_5)
            }
            if (data.Choice_6) {
              choices.push(data.Choice_6)
            }
            const obj = {
              name: data.Name,
              email: data.Email,
            }
            arr.push(obj)


          }
          setExcelData(arr)
          return setTimeout(() => submitMultiple(arr), 500)
        }
        else {
          if (data.Choice_1) {
            choices.push(data.Choice_1)
          }
          if (data.Choice_2) {
            choices.push(data.Choice_2)
          }
          if (data.Choice_3) {
            choices.push(data.Choice_3)
          }
          if (data.Choice_4) {
            choices.push(data.Choice_4)
          }
          if (data.Choice_5) {
            choices.push(data.Choice_5)
          }
          if (data.Choice_6) {
            choices.push(data.Choice_6)
          }
          const obj = {
            name: data.Name,
            email: data.Email,
          }
          arr.push(obj)


        }


      });

    };

    reader.readAsBinaryString(file);
  };


  const deleteSchedule=()=>{
    const api=`${process.env.REACT_APP_API}exam/schedule/${id}`
    window.confirm('are you sure you want to delete this schedule') && deleteRequest(api, () => {
      navigate(`/view-exam/${scheduledata.examId}`)
  }, (err) => {
      console.log(err)
      window.alert('schedule could not be deleted')
  })
  }
  const submit = () => {
    //   {
    //     "scheduleId":15505936384,
    //     "users":[

    //           {
    //             "name":"Rakshank Meena",
    //             "email":"rakshank@edslash.com"
    //         }, 
    // }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      window.alert("Please enter a valid email address")
    }
    if (name === '') {
      window.alert('Name cannot be empty')
    }
    else {
      const data = {
        scheduleId: id,
        users: [
          {
            name,
            email
          }
        ]
      }

      postRequest(`${process.env.REACT_APP_API}exam/schedule/addcandidates`, data, (data) => {
        console.log(data)
        // postRequest(`${process.env.REACT_APP_API}exam/schedule/sendmail`, {
        //   scheduleId: id
        // }, (data) => {
          window.alert('credentials sent to mail successfully')
        // }, (err) => {
        //   console.log(err)
        // })
      }, (err) => {
        console.log(err)
      })
    }
  }


  useEffect(() => {
    const cookieId:any= document.cookie.split(';').find((c)=>c.includes('ustid'))?.replace(' ustid=','') 
    setUserId(atob(cookieId ||''))
    getRequest(`${process.env.REACT_APP_API}exam/schedule/${id}`, (data: any) => {
      setSchedule(data)
      console.log(data)
    }, (err) => {
      console.log(err)
    })
  }, [])
  return (
    <div className='w-full p-12 h-auto min-h-screen bg-gradient-to-br from-white to-indigo-100 '>
      <div className='p-10 shadow-sm min-h-[80vh] h-auto w-full rounded-lg'>
        <div className='w-full flex justify-end'>
         {(scheduledata?.creatorId===userId||designation==='admin')&& <PrimaryButton onClick={deleteSchedule}>
            Delete schedule
          </PrimaryButton>}
        </div>
        <div className='w-full flex justify-center items-center my-5'>

          <span className='mx-auto text-darkBlue border-b-2 border-highlightText p-2 font-montserrat font-semibold text-2xl'>
            Schedule
          </span>
        </div>
        
        <div className='bg-white rounded-lg shadow-md px-3 py-3 flex flex-col gap-2 '>
          <div className='text-darkBlue text-xl font-montserrat font-medium'>
            Start time: <span className='text-textBlack text-lg '>
              {scheduledata?.start && new Date(scheduledata.start).toLocaleString()}</span>
          </div>
          <div className='text-darkBlue text-xl font-montserrascheduledata.startt font-medium'>
            End time <span className='text-textBlack text-lg '>
              {scheduledata && new Date(scheduledata.end).toLocaleString()}</span>
          </div>
        </div>
        <div className='w-full justify-end flex my-3'>

        <SecondaryButtons onClick={uploadMultiple}>
          Upload a file
        </SecondaryButtons>
        </div>
        <div className='w-3/4 h-auto mx-auto my-5'>
          <div className='text-darkBlue text-xl font-montserrat font-medium'>
            Enter candidate's name :
          </div>
          <input value={name} onChange={(e) => setName(e.target.value)} type='email' className='px-2 py-2 bg-gradient-to-br from-[#ececec] to-[#ececec00] w-full rounded-lg' />
        </div>
        <div className='w-3/4 h-auto mx-auto my-5'>
          <div className='text-darkBlue text-xl font-montserrat font-medium'>
            Enter candidate's email id :
          </div>
          <input value={email} onChange={(e) => setEmail(e.target.value)} type='email' className='px-2 py-2 bg-gradient-to-br from-[#ececec] to-[#ececec00] w-full rounded-lg' />
        </div>
        <div className='w-full flex justify-center'>

        <PrimaryButton onClick={submit}>
          Add candidate
        </PrimaryButton>
        </div>
      </div>
    </div>
  )
}

export default AddCandidates
