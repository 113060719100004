import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
    const [profile, setProfile] = useState<boolean>(false)
    const logout = () => {
        document.cookie = "lgdin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
        document.cookie = "ustid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
        window.location.href = '/login'
        // window.location.reload()
    }
    return (
        <div className='bg-[#ffffff]  shadow-black  h-auto flex items-center justify-between  drop-shadow px-8 py-4 '>
            <Link to={'/'}>
                <img
                    className='cursor-pointer'
                    alt={"edSlash logo"}
                    height={65}
                    width={140}
                    src={'https://edslash.com/wp-content/uploads/2021/02/edSlash-Logo-2-e1640434365249.png.webp'} />
            </Link>

            <div className='flex gap-9 items-center w-fit'>
                <Link to={'/codingquestion'}>
                    <span className='pb-2 text-textGrey font-poppins hover:text-textBlack hover:font-medium hover:border-highlightText border-b-2  border-transparent cursor-pointer text-sm'>
                        Add Coding Question
                    </span>
                </Link>
                <Link to={'/mcq'}>
                    <span className='pb-2 text-textGrey font-poppins hover:text-textBlack hover:font-medium hover:border-highlightText border-b-2  border-transparent cursor-pointer text-sm'>
                        Add MCQ
                    </span>
                </Link>

                <Link to={'/create-exam'}>
                    <span className='pb-2 text-textGrey font-poppins hover:text-textBlack hover:font-medium hover:border-highlightText border-b-2  border-transparent cursor-pointer text-sm'>
                        Create Exam
                    </span>
                </Link>
                <Link to={'/view-exam'}>
                    <span className='pb-2 text-textGrey font-poppins hover:text-textBlack hover:font-medium hover:border-highlightText border-b-2  border-transparent cursor-pointer text-sm'>
                        View Exams
                    </span>
                </Link>
                <span className='text-textGrey cursor-pointer relative'>
                    <svg onClick={() => setProfile(!profile)} fill="none" viewBox="0 0 24 24" height="1.4em" width="1.4em" >
                        <path
                            stroke='#ffffff'
                            strokeWidth={'0.6'}
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M16 9a4 4 0 11-8 0 4 4 0 018 0zm-2 0a2 2 0 11-4 0 2 2 0 014 0z"
                            clipRule="evenodd"
                        />
                        <path
                            stroke='#ffffff'
                            strokeWidth={'0.6'}
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zM3 12c0 2.09.713 4.014 1.908 5.542A8.986 8.986 0 0112.065 14a8.984 8.984 0 017.092 3.458A9 9 0 103 12zm9 9a8.963 8.963 0 01-5.672-2.012A6.992 6.992 0 0112.065 16a6.991 6.991 0 015.689 2.92A8.964 8.964 0 0112 21z"
                            clipRule="evenodd"
                        />
                    </svg>
                    {profile && <div className='absolute top-6 right-0 p-4 bg-[#f5f5f5] rounded-lg shadow-lg flex flex-col'>
                        <div onClick={logout} className='text-textGrey text-sm cursor-pointer flex items-center gap-2'>
                            Logout
                            <svg fill="none" viewBox="0 0 15 15" height="1em" width="1em" >
                                <path
                                    fill="currentColor"
                                    fillRule="evenodd"
                                    d="M3 1a1 1 0 00-1 1v11a1 1 0 001 1h7.5a.5.5 0 000-1H3V2h7.5a.5.5 0 000-1H3zm9.604 3.896a.5.5 0 00-.708.708L13.293 7H6.5a.5.5 0 000 1h6.793l-1.397 1.396a.5.5 0 00.708.708l2.25-2.25a.5.5 0 000-.708l-2.25-2.25z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>
                    </div>}
                </span>
            </div>
        </div >
    )
}

export default Navbar
