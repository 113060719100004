import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import CodingQuestionHandler from './pages/CodingQuestionHandler';
import TestCaseView from './pages/TestCaseView';
import EditCodingQuestion from './pages/EditCodingQuestion';
import TestCaseHandler from './pages/TestCaseHandler';
import AddStarterCode from './pages/AddStarterCode';
import ViewStarterCode from './pages/ViewStarterCode';
import McqHandler from './pages/McqHandler';
import EditMcq from './pages/EditMcq';
import CreateExam from './pages/CreateExam';
import CreateExamConfirm from './pages/CreateExamConfirm';
import ViewExam from './pages/ViewExam';
import ExamDetail from './pages/ExamDetail';
import Login from './pages/Login';
import AddCandidates from './pages/AddCandidates';
import NavWrap from './components/NavWrap';

function App() {

  useEffect(() => {
   ( !document.cookie.includes('lgdin')&&!window.location.href.includes('login') )&& (window.location.href = '/login')
  }, [])
  return (
    <div className='font-manrope min-h-screen w-full'>
      <Router>
        <NavWrap>
          <Routes>
            <Route path={'/'} element={<HomePage />} />
            <Route path={'/login'} element={<Login />} />
            <Route path={'/viewcq/:id'} element={<CodingQuestionHandler />} />
            <Route path={'/create-exam'} element={<CreateExam />} />
            <Route path={'/create-exam-final'} element={<CreateExamConfirm />} />
            <Route path={'/view-exam'} element={<ViewExam />} />
            <Route path={'/view-exam/:id'} element={<ExamDetail />} />
            <Route path={'/viewmcq/:id'} element={<McqHandler />} />
            <Route path={'/codingquestion'} element={<EditCodingQuestion />} />
            <Route path={'/mcq'} element={<EditMcq />} />
            <Route path={'/codingquestion/:id'} element={<EditCodingQuestion />} />
            <Route path={'/schedule-view/:id'} element={<AddCandidates />} />
            <Route path={'/mcq/:id'} element={<EditMcq />} />
            <Route path={'/viewtestcases/:id'} element={<TestCaseView />} />
            <Route path={'/testcase/:id'} element={<TestCaseHandler />} />
            <Route path={'/startercode/:id'} element={<AddStarterCode />} />
            <Route path={'/viewstartercode/:id'} element={<ViewStarterCode />} />
          </Routes>
        </NavWrap>
      </Router>
    </div>
  );
}

export default App;
