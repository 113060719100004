import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import { useLocation } from 'react-router-dom'

const NavWrap = (props: { children: any }) => {
    const location = useLocation()
    useEffect(() => {
        console.log(12)
    }, [location])
    return (
        <>
            {!location.pathname.includes('login') && <Navbar />}
            {props.children}
        </>
    )
}

export default NavWrap
