import React, { memo, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import DropDown from '../components/DropDown'
import { PrimaryButton, SecondaryButtons } from '../components/Buttons'
import { postRequest } from '../ApiFunctions.tsx/post'
import Modal from '../components/Modal'
import { getRequest } from '../ApiFunctions.tsx/get'
import { putRequest } from '../ApiFunctions.tsx/put'
import { deleteRequest } from '../ApiFunctions.tsx/delete'
import * as XLSX from 'xlsx';
import { tagsHardArr } from './EditCodingQuestion'

const EditMcq = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [title, setTitle] = useState<string>('')
    const [statement, setStatement] = useState<string>('')
    const [difficulty, setDifficulty] = useState<string>('easy')
    const [open, setOpen] = useState<boolean>(false)
    const [options, setOptions] = useState<any[]>([{ choice: '', type: '' }, { choice: '', type: '' }])
    const dispRef = useRef<HTMLDivElement>(null)
    const [choicesMcq, setChoicesMcq] = useState<any[]>([])
    const [tag, setTag] = useState<any[]>([])
    const [tagOpen, setTagOpen] = useState(false)
    const [excelData, setExcelData] = useState<any[]>([])
    const difficultyArr = [
        'easy',
        'medium',
        'hard'
    ]
    const addOption = () => {
        setOptions((option) => [...option, { choice: '', type: '' }])

    }

    const addTag = (data: any) => {
        if (tag.includes(data)) {
            const filteredTags = tag.filter(tag => tag !== data)
            setTag(filteredTags)
        }
        else {
            setTag((tagArr) => [...tagArr, data])
        }
    }
    const submitMultiple = () => {
        let success: any = []
        let fail: any = []
        excelData.map((data: any, i: number) => {

            postRequest(process.env.REACT_APP_API + 'mcq/add', data, () => {
                if (i === excelData.length - 1) {
                    fail.push(data)
                    console.log(fail)
                    console.log(success)
                }
                else {
                    success.push(data)
                }
                // window.alert('mcq added successfully')
                // navigator('/')
            }, (err) => {
                if (i === excelData.length - 1) {
                    fail.push(data)
                    console.log(fail)
                    console.log(success)
                    window.alert('mcq added successfully')
                    navigate('/')
                }
                else {
                    fail.push(data)

                }
            })
            var elementToRemove = document.getElementById('fakeinput');
            if (elementToRemove) {
                document.removeChild(elementToRemove);
                window.alert('mcq added successfully')
                navigate('/')
            }
            window.alert('mcq added successfully')
            navigate('/')
        })
    }

    const uploadMultiple = () => {
        const inputElement = document.createElement('input');
        inputElement.type = 'file';
        inputElement.id = `fakeinput`;
        inputElement.addEventListener('change', function (e: any) {
            // console.log('File selected:', this.files[0].name);
            const file = e.target.files[0];
            if (file) {
                readExcel(file);


            }
            // Add your custom logic here after the file is selected
        });
        inputElement.click()
    }

    const readExcel = (file: any) => {
        const reader = new FileReader();

        reader.onload = (e: any) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });

            // Assuming you have only one sheet
            const sheetName = workbook.SheetNames[0];
            const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
            let arr: any[] = []
            excelData.map((data: any, i: number) => {
                let choices: any[] = []

                if (i === excelData.length - 1) {

                    if (data.type) {
                        if (data.Choice_1) {
                            choices.push(data.Choice_1)
                        }
                        if (data.Choice_2) {
                            choices.push(data.Choice_2)
                        }
                        if (data.Choice_3) {
                            choices.push(data.Choice_3)
                        }
                        if (data.Choice_4) {
                            choices.push(data.Choice_4)
                        }
                        if (data.Choice_5) {
                            choices.push(data.Choice_5)
                        }
                        if (data.Choice_6) {
                            choices.push(data.Choice_6)
                        }
                        const obj = {
                            type: data.type,
                            title: data.title,
                            textlanguage: data.textLanguage,
                            statement: data.statement,
                            competencies: data.competencies,
                            instructions: data.instructions,
                            tags: data.tags.split(','),
                            difficulty: data.difficulty.toLowerCase(),
                            choices: choices,
                            correctChoices: [choices[parseInt(data.correctChoices) - 1]],
                            scoreForCorrectChoices: data.scoreForCorrectChoices,
                            scoreForIncorrectChoices: data.scoreForIncorrectChoices,
                            randomizeChoices: data.randomizeChoices.toLowerCase() === 'yes' ? true : false
                        }
                        arr.push(obj)


                    }
                    setExcelData(arr)
                    return setTimeout(() => submitMultiple(), 500)
                }
                else {
                    if (data.Choice_1) {
                        choices.push(data.Choice_1)
                    }
                    if (data.Choice_2) {
                        choices.push(data.Choice_2)
                    }
                    if (data.Choice_3) {
                        choices.push(data.Choice_3)
                    }
                    if (data.Choice_4) {
                        choices.push(data.Choice_4)
                    }
                    if (data.Choice_5) {
                        choices.push(data.Choice_5)
                    }
                    if (data.Choice_6) {
                        choices.push(data.Choice_6)
                    }
                    const obj = {
                        type: data.type,
                        title: data.title,
                        textlanguage: data.textLanguage,
                        statement: data.statement,
                        competencies: data.competencies,
                        instructions: data.instructions,
                        tags: data.tags.split(','),
                        difficulty: data.difficulty.toLowerCase(),
                        choices: choices,
                        correctChoices: [choices[parseInt(data.correctChoices) - 1]],
                        scoreForCorrectChoices: data.scoreForCorrectChoices,
                        scoreForIncorrectChoices: data.scoreForIncorrectChoices,
                        randomizeChoices: data.randomizeChoices.toLowerCase() === 'yes' ? true : false
                    }
                    arr.push(obj)


                }


            });

        };

        reader.readAsBinaryString(file);
    };


    const submit = () => {

        let choices: string[] = []
        let correctChoices: string[] = []

        options.map((option, i) => {
            const opt = (document.getElementById(`option${i}`) as HTMLInputElement) !== null ? (document.getElementById(`option${i}`) as HTMLInputElement).value : ''
            const correctOpt = (document.getElementById(`${i}check`) as HTMLInputElement) !== null ? (document.getElementById(`${i}check`) as HTMLInputElement)?.value : ''
            opt && choices.push(opt)
            opt && (correctOpt === 'on' && correctChoices.push(opt))
        })
        if (title === '' || statement === '' || choices.length === 0 || correctChoices.length === 0 || choices.includes('')) {
            console.log(choices, correctChoices)
            window.alert('all fields are required')
        }
        else {
            const data = {
                type: correctChoices.length > 1 ? "Multiple Correct" : 'Single Correct',
                title: title,
                textlanguage: "English",
                competencies: "",
                instructions: "Choose the correct option.",
                statement: statement,
                tags: tag,
                difficulty: difficulty,
                scoreForCorrectChoices: "1",
                scoreForIncorrectChoices: "0",
                randomizeChoices: true,
                choices,
                correctChoices
            }

            if (id) {
                putRequest(`${process.env.REACT_APP_API}mcq/edit/${id}`, data, () => {
                    // window.alert('mcq added successfully')
                    setOpen(true)
                }, (err) => {
                    console.log(err)
                })
            }
            else {
                postRequest(process.env.REACT_APP_API + 'mcq/add', data, () => {
                    // window.alert('mcq added successfully')
                    setOpen(true)
                }, (err) => {
                    console.log(err)
                })
            }
        }
    }

    const deleteMcq = () => {
        window.confirm('are you sure you want to delete this question?') && deleteRequest(`${process.env.REACT_APP_API}mcq/${id}`, (data) => {
            setOpen(true)
        }, (err) => {
            console.log(err)
        })
    }


    useEffect(() => {
        getRequest(`${process.env.REACT_APP_API}mcq/${id}`, (data) => {
            setTitle(data.title)
            setStatement(data.statement)
            setTag(data.tags)
            const optArr: any = []
            data.choices.map((choice: any, i: number) => {
                if (i === data.choices.length - 1) {
                    optArr.push({ choice, type: '' })
                    setOptions(optArr)
                } else {

                    optArr.push({ choice, type: '' })
                }
            })
        }, (err) => {
            console.log(err)
        })
    }, [])
    return (
        <div className={` bg-gradient-to-br from-[#FBFBFB00]  to-[#DFEAFD] w-full h-auto min-h-screen px-24 py-[2.5rem]`}>
            <div className='w-full flex justify-center items-center'>

                <span className='mx-auto text-darkBlue border-b-2 border-highlightText p-2 font-montserrat font-semibold text-2xl'>
                    {id ? 'Edit MCQ question' : 'Add MCQ question'}
                </span>
            </div>
            <div className='w-full flex justify-between items-center mb-5'>

                <svg onClick={() => id ? navigate(`/viewmcq/${id}`) : navigate('/')} className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 24 21" fill="none">
                    <path d="M23.2503 10.0001C23.2503 9.50282 23.0528 9.02591 22.7011 8.67428C22.3495 8.32265 21.8726 8.1251 21.3753 8.1251L6.5253 8.1251L11.4503 3.2001C11.7815 2.84467 11.9618 2.37455 11.9532 1.8888C11.9447 1.40305 11.7479 0.939583 11.4044 0.59605C11.0608 0.252518 10.5974 0.0557365 10.1116 0.0471649C9.62586 0.0385952 9.15574 0.218904 8.8003 0.550106L0.675303 8.6751C0.324177 9.02667 0.126953 9.50323 0.126953 10.0001C0.126953 10.497 0.324177 10.9735 0.675303 11.3251L8.8003 19.4501C8.97196 19.6343 9.17896 19.7821 9.40896 19.8846C9.63896 19.987 9.88724 20.0421 10.139 20.0466C10.3908 20.051 10.6408 20.0047 10.8743 19.9104C11.1078 19.8161 11.3199 19.6757 11.4979 19.4977C11.6759 19.3197 11.8163 19.1076 11.9106 18.8741C12.0049 18.6406 12.0512 18.3906 12.0468 18.1388C12.0423 17.887 11.9872 17.6388 11.8848 17.4088C11.7823 17.1788 11.6345 16.9718 11.4503 16.8001L6.5253 11.8751L21.3753 11.8751C22.4103 11.8751 23.2503 11.0351 23.2503 10.0001Z" fill="#01044F" />
                </svg>


                {id ? <SecondaryButtons classes='flex gap-1' onClick={deleteMcq}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M15.1464 4.35355L15.2929 4.5H15.5H18.5V5.5H5.5V4.5H8.5H8.70711L8.85355 4.35355L9.70711 3.5H14.2929L15.1464 4.35355ZM8 20.5C7.17614 20.5 6.5 19.8239 6.5 19V7.5H17.5V19C17.5 19.8239 16.8239 20.5 16 20.5H8Z"
                            stroke="#2575FC" />
                    </svg>
                    Delete
                </SecondaryButtons> : <SecondaryButtons onClick={uploadMultiple}>
                    Upload a file
                </SecondaryButtons>}
            </div>
            <div className='w-full min-h-[60vh] h-auto flex gap-5'>

                <div className='w-full h-auto min-h-full flex flex-col bg-white rounded-lg px-7 py-12'>
                    <input
                        placeholder="enter a title for the question"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className=' text-darkBlue focus:outline-none py-3 px-4 font-semibold font-manrope mb-8 text-xl bg-gradient-to-br from-[#ebebeb] to-[#ebebeb00] rounded-lg' />


                    <textarea className='text-darkBlue  focus:outline-none py-3 px-4 resize-none h-[60vh] rounded-lg bg-gradient-to-br from-[#ebebeb] to-[#ebebeb00] text-base font-manrope capitalize '
                        value={statement}
                        placeholder='Enter a description'
                        onChange={(e) => setStatement(e.target.value)}
                    />
                    <div className='w-full h-fit my-2'>
                        <div className='w-auto flex space-x-2 space-y-1 flex-wrap h-fit items-center'>
                            {tag.map((tags: any, i: number) => {
                                return <span
                                    key={`tags-${i}`}
                                    className={`${tag.includes(tags) ? 'bg-highlightText text-white' : 'bg-white text-highlightText'} 
                            py-3 px-3 w-fit h-min font-poppins border text-sm
                            border-highlightText rounded-full cursor-pointer`}>
                                    {tags}
                                    <span onClick={() => addTag(tags)} className='p-1 font-manrope text-white ml-2 font-bold text-sm cursor-pointer'>
                                        x
                                    </span>
                                </span>
                            })}
                            <span onClick={() => setTagOpen(true)} className=' py-3 px-3 font-poppins text-sm w-fit h-min text-highlightText border border-highlightText rounded-full cursor-pointer'>Add tag +</span>
                        </div>
                    </div>
                </div>

                <div className='w-full h-auto min-h-full bg-white rounded-lg px-7 py-12'>
                    <DropDown
                        classes='!w-max '

                        onComponentClick={(data: any) => { setDifficulty(data) }}
                        editable={true}
                        array={difficultyArr}
                        displayText={`difficulty : ${difficulty}`}
                    />
                    <div ref={dispRef} className='mt-6 flex flex-col gap-5'>
                        {
                            options.map((e: any, i: number) => {
                                return <OptionComponent e={e}
                                    // setOptions={setOptions} 
                                    // options={options} 
                                    key={`optionCreation${i}`} id={i} />
                            })
                        }
                    </div>
                    <div className='my-5'>

                        <SecondaryButtons onClick={() => { addOption() }}>
                            Add Option
                        </SecondaryButtons>
                    </div>

                </div>
            </div>
            <div className='w-min mx-auto mt-5'>
                <PrimaryButton onClick={submit}>
                    submit
                </PrimaryButton>

            </div>
            {tagOpen && <div className='fixed top-0 left-0 w-screen h-screen backdrop-blur-md flex justify-center items-center '>
                <div className='bg-white rounded-lg px-3 py-3 flex flex-wrap overflow-auto space-x-2 space-y-1 w-1/2 h-1/2'>
                    <div className='w-full h-auto flex justify-between px-3 my-3'>
                        <div className='text-darkBlue font-montserrat font-medium text-lg capitalize'>
                            Tags
                        </div>
                        <div onClick={() => setTagOpen(false)} className='font-manrope font-bold text-textGrey text-xl cursor-pointer'>x</div>
                    </div>
                    {tagsHardArr.map((tags: any, i: number) => {
                        return <span
                            key={`tags-${i}`}
                            onClick={() => addTag(tags)}
                            className={`${tag.includes(tags) ? 'bg-highlightText text-white' : 'bg-white text-highlightText'} 
                            py-3 px-3 w-fit h-min font-poppins border text-sm
                            border-highlightText rounded-full cursor-pointer`}>
                            {tags}</span>
                    })}
                </div>
            </div>}
            <Modal text={'MCQ added succesfully'} open={open} setOpen={setOpen} onClick={() => navigate('/')} />
        </div>
    )
}

export default EditMcq

const OptionComponent = memo((props: { e: any, id: number, value?: boolean }) => {
    const [check, setChecked] = useState<string>('off')

    const [opt, setOpt] = useState<string>('')
    const delFn = (id: any) => {
        document.getElementById(`option${id}`)?.remove()

        document.getElementById(`${id}checkgroup`)?.remove()


    }
    useEffect(() => {
        setOpt(props.e.choice)
    }, [props])

    return (
        <div id={`optiongroupmcq${props.id}`} className='w-full h-auto flex justify-between items-center gap-3 '>
            <input
                value={opt}
                onChange={(e) => setOpt(e.target.value)}
                id={`option${props.id}`}
                className='focus:outline-none py-3 px-4 bg-gradient-to-b from-[#ebebeb00] to-[#ebebeb] rounded-lg w-full ' />
            <div id={`${props.id}checkgroup`} className='flex gap-2 items-center'>
                <div className={`h-4 w-4 rounded-[4px] flex justify-center items-center `}>
                    <input
                        className=''
                        id={`${props.id}check`}
                        type="checkbox"
                        value={check}
                        onChange={(e) => { e.target.value === 'off' ? setChecked('on') : setChecked('off') }}
                    />
                </div>
                <span className='text-inputBorder text-xs'>
                    mark as correct
                </span>
                <div onClick={() => delFn(props.id)} className='w-fit h-fit cursor-pointer'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M15.1464 4.35355L15.2929 4.5H15.5H18.5V5.5H5.5V4.5H8.5H8.70711L8.85355 4.35355L9.70711 3.5H14.2929L15.1464 4.35355ZM8 20.5C7.17614 20.5 6.5 19.8239 6.5 19V7.5H17.5V19C17.5 19.8239 16.8239 20.5 16 20.5H8Z" stroke="#2575FC" />
                    </svg>
                </div>
            </div>

        </div>
    )
})