import { useEffect, useState } from 'react'
import { PrimaryButton, SecondaryButtons } from '../components/Buttons'
import { CodeBlock } from 'react-code-blocks'
import { useNavigate, useParams } from 'react-router-dom'
import { getRequest } from '../ApiFunctions.tsx/get'
import { languagesArr } from '../components/GlobalConst'
import { deleteRequest } from '../ApiFunctions.tsx/delete'
import { useUserData } from '../components/useUserData'
const CodingQuestionHandler = () => {
    const { id } = useParams()
    const { designation, userId } = useUserData()
    const navigate = useNavigate()

    const [data, setData] = useState<any>({
        id: "",
        title: "",
        desc: "",
        difficulty: "",
        tags: [
        ],
        solution_language: 0,
        solution: ""
    })

    const deleteProblem = () => {
        const api = `${process.env.REACT_APP_API}problem/${id}`
        window.confirm('are you sure you want to delete this question') && deleteRequest(api, () => {
            navigate('/')
        }, (err) => {
            console.log(err)
            window.alert('Question could not be deleted')
        })
    }

    useEffect(() => {


        const onSuccess = (data: any) => {
            setData(data)
        }
        const onFailure = (err: any) => {
            console.log(err)
        }
        getRequest(`${process.env.REACT_APP_API}problem/${id}`, onSuccess, onFailure)
    }, [id])


    return (
        <div className={`bg-gradient-to-br  from-white to-whitesky min-h-screen w-full h-auto py-8 px-24 `}>
            <p className='mb-4 '>
                <svg onClick={() => navigate('/')} className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 24 21" fill="none">
                    <path d="M23.2503 10.0001C23.2503 9.50282 23.0528 9.02591 22.7011 8.67428C22.3495 8.32265 21.8726 8.1251 21.3753 8.1251L6.5253 8.1251L11.4503 3.2001C11.7815 2.84467 11.9618 2.37455 11.9532 1.8888C11.9447 1.40305 11.7479 0.939583 11.4044 0.59605C11.0608 0.252518 10.5974 0.0557365 10.1116 0.0471649C9.62586 0.0385952 9.15574 0.218904 8.8003 0.550106L0.675303 8.6751C0.324177 9.02667 0.126953 9.50323 0.126953 10.0001C0.126953 10.497 0.324177 10.9735 0.675303 11.3251L8.8003 19.4501C8.97196 19.6343 9.17896 19.7821 9.40896 19.8846C9.63896 19.987 9.88724 20.0421 10.139 20.0466C10.3908 20.051 10.6408 20.0047 10.8743 19.9104C11.1078 19.8161 11.3199 19.6757 11.4979 19.4977C11.6759 19.3197 11.8163 19.1076 11.9106 18.8741C12.0049 18.6406 12.0512 18.3906 12.0468 18.1388C12.0423 17.887 11.9872 17.6388 11.8848 17.4088C11.7823 17.1788 11.6345 16.9718 11.4503 16.8001L6.5253 11.8751L21.3753 11.8751C22.4103 11.8751 23.2503 11.0351 23.2503 10.0001Z" fill="#01044F" />
                </svg>
            </p>
            <div className='w-full  h-auto flex justify-between items-center mb-7 '>
                <SecondaryButtons onClick={() => { navigate(`/viewtestcases/${id}`) }}>
                    View Test case
                </SecondaryButtons>
                <div className='flex items-center w-fit gap-8'>
                    <SecondaryButtons onClick={() => { navigate(`/testcase/${id}`) }}>
                        Add Test case
                    </SecondaryButtons>
                    <SecondaryButtons classes='flex gap-1' onClick={() => { navigate(`/codingquestion/${id}`) }}>
                        Edit
                        <svg xmlns="http://www.w3.org/2000/svg"
                            width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <path d="M2.625 18.375V14.6562L14.175 3.12813C14.35 2.96771 14.5434 2.84375 14.7551 2.75625C14.9669 2.66875 15.1891 2.625 15.4219 2.625C15.6552 2.625 15.8812 2.66875 16.1 2.75625C16.3187 2.84375 16.5083 2.975 16.6687 3.15L17.8719 4.375C18.0469 4.53542 18.1746 4.725 18.2551 4.94375C18.3356 5.1625 18.3756 5.38125 18.375 5.6C18.375 5.83333 18.335 6.05588 18.2551 6.26763C18.1752 6.47938 18.0475 6.67246 17.8719 6.84687L6.34375 18.375H2.625ZM15.4 6.825L16.625 5.6L15.4 4.375L14.175 5.6L15.4 6.825Z"
                                fill="currentColor" />
                        </svg>
                    </SecondaryButtons>
                </div>
            </div>

            <div className='flex gap-5 w-full h-auto'>



                <div className='w-full h-auto min-h-[80vh] bg-white  shadow-inner-md py-11 px-6 rounded-lg shadow-[#E5EEFD] flex flex-col'>
                    <div className='w-full flex justify-between items-center'>
                        <span className='w-max overflow-clip whitespace-nowrap text-ellipsis text-darkBlue text-2xl font-manrope font-semibold'>
                            {data.title ? data.title : ''}
                        </span>
                        <div
                            className={` px-6 py-5 text-base text-darkBlue 
                        font-medium font-poppins border relative rounded-xl
                        border-highlightText flex items-center w-max justify-between bg-white `}>
                            <span >
                                Difficulty
                            </span>
                            <span className={`capitalize ${data.difficulty === 'easy' ? 'text-[#2EA6A3]' : (data.difficulty === 'medium' ? 'text-[#FBDD8D]' : 'text-[#FF8E96]')}`}>
                                : {data.difficulty}
                            </span>
                        </div>
                    </div>

                    <div className='w-auto my-8 h-auto font-manrope text-base text-textBlack capitalize'>
                        {data.desc}
                    </div>
                    <div className='mt-auto'>
                        <div className='w-full h-fit my-2'>
                            <div className='w-auto flex space-x-2 space-y-1 flex-wrap h-fit items-center'>
                                {data.tags.map((tags: any, i: number) => {
                                    return <span
                                        key={`tags-${i}`}
                                        className={`${data.tags.includes(tags) ? 'bg-highlightText text-white' : 'bg-white text-highlightText'} 
                            py-3 px-3 w-fit h-min font-poppins border text-sm
                            border-highlightText rounded-full cursor-pointer`}>
                                        {tags}

                                    </span>
                                })}
                                {/* <span onClick={() => setTagOpen(true)} className=' py-3 px-3 font-poppins text-sm w-fit h-min text-highlightText border border-highlightText rounded-full cursor-pointer'>Add tag +</span> */}
                            </div>
                        </div>
                        <SecondaryButtons classes='!px-5 !py-3 !mt-auto !w-fit' onClick={() => { navigate(`/startercode/${id}`) }}>
                            Add Starter Code
                        </SecondaryButtons>
                    </div>

                </div>




                <div className='w-full h-auto min-h-[80vh] bg-white  shadow-inner-md py-11 px-6 rounded-lg shadow-[#E5EEFD]'>
                    <div className='w-full flex justify-between items-center'>
                        <span className='w-max overflow-clip whitespace-nowrap text-ellipsis text-darkBlue text-2xl font-manrope font-semibold'>
                            Solution :
                        </span>
                        <div
                            className={` px-6 py-5 text-base text-darkBlue 
                        font-medium font-poppins border relative rounded-xl
                        border-highlightText flex justify-between bg-white `}>
                            <span >
                                Language
                            </span>
                            <span className={`capitalize `}>
                                : {languagesArr.filter((language) => language.id === data.solution_language)[0]?.name.split(' (')[0]}
                            </span>
                        </div>
                    </div>
                    <CodeBlock
                        customStyle={{ backgroundColor: '#ffffff', minHeight: '90%', height: `auto`, width: "40vw", overflow: "auto", fontFamily: "'Source Code Pro', monospace", fontSize: '16px' }}
                        text={atob(data.solution)}
                        language={languagesArr.filter((language) => language.id === data.solution_language)[0]?.name.split(' (')[0].toLowerCase()}
                        showLineNumbers={true}
                    // theme={props.darkMode === 'dark' ? codepen : monoBlue}
                    />
                </div>
            </div>
            <div className='w-min mx-auto mt-4'>
                {(data.authorId === userId || designation === 'admin') && <PrimaryButton onClick={deleteProblem}>
                    Delete
                </PrimaryButton>}
            </div>
        </div>
    )
}

export default CodingQuestionHandler
