import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../components/Buttons'
import { postRequest } from '../ApiFunctions.tsx/post'

const Login = () => {
    const navigate = useNavigate()
    const [username, setUserName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const typeRef = useRef<any>(null)
    // const submit = () => {
    //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     if (email === '' || username === '' || password === '') {
    //         window.alert("all fields are necessary")
    //     }
    //     else if (!emailRegex.test(email)) {
    //         window.alert("invalid email")
    //     }
    //     else {
    //         const data = {
    //             name: username,
    //             email,
    //             password,
    //             designation: typeRef ? typeRef.current.value : ''
    //         }
    //         const url = process.env.REACT_APP_JUDGE0_API + 'auth/register'

    //         postRequest(url,
    //             data, (data: any) => {
    //                 function setCookie(cname:any, cvalue:any, expires:any) {
    //                     const d = new Date();
    //                     d.setTime(d.getTime() + (expires * 24 * 60 * 60 * 1000));
    //                     const expire = "expires=" + d.toUTCString();
    //                     document.cookie = cname + "=" + cvalue + ";" + expire + ";path=/";
    //                   }

    //                   setCookie("lgdin", btoa('true'), 1);
    //                 // console.log(data)
    //             }, (err: any) => {
    //                 postRequest(process.env.REACT_APP_JUDGE0_API + 'auth/login',
    //                 {
    //                     email,
    //                     password,
    //                 }, (data: any) => {
    //                         function setCookie(cname:any, cvalue:any, expires:any) {
    //                             const d = new Date();
    //                             d.setTime(d.getTime() + (expires * 24 * 60 * 60 * 1000));
    //                             const expire = "expires=" + d.toUTCString();
    //                             document.cookie = cname + "=" + cvalue + ";" + expire + ";path=/";
    //                           }

    //                           setCookie("lgdin", btoa('true'), 1);
    //                         // console.log(data)
    //                     }, (err: any) => {
    //                         console.log(err)
    //                     }
    //                     )
    //             }
    //             )

    //         console.log(data)
    //     }
    // }
    const submit = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email === '' || password === '') {
            window.alert("all fields are necessary")
        }
        else if (!emailRegex.test(email)) {
            window.alert("invalid email")
        }
        else {
            postRequest(process.env.REACT_APP_API + 'auth/login',
                {
                    email,
                    password,
                }, (data: any) => {
                    function setCookie(cname: any, cvalue: any, expires: any) {
                        const d = new Date();
                        d.setTime(d.getTime() + (expires * 24 * 60 * 60 * 1000));
                        const expire = "expires=" + d.toUTCString();
                        document.cookie = cname + "=" + cvalue + ";" + expire + ";path=/";
                    }
                    localStorage.setItem('user', JSON.stringify(data))
                    setCookie("lgdin", btoa('true'), 1);
                    setCookie("ustid", btoa(data.id), 1);
                    // console.log(data)
                    navigate('/')
                }, (err: any) => {
                    console.log(err)
                }
            )
        }
    }
    useEffect(() => {
        document.cookie.includes('lgdin') && navigate('/')
    })
    return (
        <div className='h-screen w-full overflow-clip relative px-[6.5vw] py-[11vh] '>
            <div className='flex h-full w-[70vw] mx-auto border bg-white rounded-lg relative z-10 overflow-clip'>
                <div className='w-fit h-full'>
                    <img src={'./assets/Image.png'} className='w-auto h-full' alt={'banner'} />
                </div>
                <div className='w-auto h-full py-10 px-10 flex justify-center items-center'>
                    <div className='w-fit h-full flex justify-between items-center flex-col'>
                        <div className='font-montserrat whitespace-nowrap w-fit mx-auto font-bold text-[44px] text-darkBlue flex flex-col items-center '>

                            Welcome to edSlash

                            <img
                                className='cursor-pointer'
                                alt={"edSlash logo"}
                                height={65}
                                width={140}
                                src={'https://edslash.com/wp-content/uploads/2021/02/edSlash-Logo-2-e1640434365249.png.webp'} />
                        </div>
                        <div className='w-auto h-auto flex gap-3 flex-col'>
                            <div className='bg-whitesky border  border-inputBorder rounded-lg flex justify-center items-center px-3'>
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="#9a9a9a"
                                    height="1em"
                                    width="1em"
                                >
                                    <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6m-2 0l-8 5-8-5h16m0 12H4V8l8 5 8-5v10z" />
                                </svg>
                                <input value={email} onChange={(e: any) => setEmail(e.target.value)} type="Email" placeholder="Email" className='w-[28.63vw] text-base py-3 px-3 rounded-lg bg-whitesky focus:outline-none ' />
                            </div>


                            <div className='bg-whitesky border  border-inputBorder rounded-lg flex justify-center items-center px-3'>
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="#9a9a9a"
                                    height="1em"
                                    width="1em"
                                >
                                    <path d="M12 2C9.243 2 7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zm6 10l.002 8H6v-8h12zm-9-2V7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9z" />
                                </svg>
                                <input value={password} onChange={(e: any) => setPassword(e.target.value)} type="password" placeholder="Password" className='w-[28.63vw] text-base py-3 px-3 rounded-lg bg-whitesky focus:outline-none ' />
                            </div>


                        </div>
                        <div className='w-fit mx-auto'>
                            <PrimaryButton classes='!rounded-full' onClick={submit}>
                                Sign in
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
            <span className="z-20 h-[31vw] w-[31vw] rounded-full absolute left-[-10.5vw] bottom-[-31vh] bg-gradient-to-tr  from-highlightText to-[#2575FC00] " ></span>
            <span className=" h-[52vh] w-[46vw] rotate-[-42.54deg]  absolute right-[-29.48vw] top-[-27vh] bg-gradient-to-bl  from-highlightText to-[#2575FC00] " ></span>
        </div>
    )
}

export default Login


