import { useEffect, useState } from 'react'
import DropDown from '../DropDown'
import { getRequest } from '../../ApiFunctions.tsx/get'
import { useNavigate } from 'react-router-dom'
import QuestionListing from './QuestionListing'
import { PrimaryButton, SecondaryButtons } from '../Buttons'
import { deleteRequest } from '../../ApiFunctions.tsx/delete'
import { useUserData } from '../useUserData'
import { tagsHardArr } from '../../pages/EditCodingQuestion'

const HomePageListing = () => {
    const navigate = useNavigate()
    const { designation } = useUserData()
    const [questionArr, setQuestionArr] = useState<any[]>([])
    const [mcq, setMcq] = useState<any[]>([])
    const [tag, setTag] = useState('all')
    const [type, setType] = useState<string>('coding')
    const [page, setPage] = useState<number>(1)
    const [search, setSearch] = useState('')
    const [arrayDelete, setArrayDelete] = useState<any[]>([])
    const [mcqDelete, setMcqDelete] = useState<any[]>([])
    const [deletion, setDelete] = useState<boolean>(false)
    const [topic, setTopic] = useState<any[]>([])
    const [tagOpen, setTagOpen] = useState<boolean>(false)
    const filter = [
        'all',
        'added by me',
        'easy',
        'medium',
        'hard'
    ]
    const addToDelete = (data: any) => {
        if (arrayDelete.includes(data)) {
            setArrayDelete((arr) => arr.filter((item) => item !== data))
        }
        else {
            setArrayDelete((arr) => [...arr, data])
        }
    }

    const addTag = (data: any) => {
        if (topic.includes(data)) {
            const filteredTags = topic.filter(tag => tag !== data)
            setTopic(filteredTags)
        }
        else {
            setTopic((tagArr) => [...tagArr, data])
        }
    }
    const addToDeleteMcq = (data: any) => {
        if (mcqDelete.includes(data)) {
            setMcqDelete((arr) => arr.filter((item) => item !== data))
        }
        else {
            setMcqDelete((arr) => [...arr, data])
        }
    }



    const onFilterChange = (data: any) => {
        setTag(data)
    }

    const deleteQuestions = () => {
        designation === 'admin' ? setTag('all') : setTag('added by me')
        setDelete(!deletion)
    }


    const deleteSelected = () => {

        if (window.confirm('Are you sure you want to delete slected questions')) {
            arrayDelete.map((id: any) => {
                const api = `${process.env.REACT_APP_API}problem/${id}`
                deleteRequest(api, (data) => {
                    getRequest(`${process.env.REACT_APP_API}problem/all?${tag === 'added by me' ? 'created=true' : ((tag === 'all') ? '' : `difficulty=${tag}`)}&page=${page}&title=${search}`, (data: any) => {

                        setQuestionArr(data.problems)
                    }, (err: any) => {
                        console.log(err)
                    })
                }, (err) => {

                })
            })
            mcqDelete.map((id: any) => {
                const api = `${process.env.REACT_APP_API}mcq/${id}`
                deleteRequest(api, (data) => {
                    getRequest(`${process.env.REACT_APP_API}mcq/all?${tag === 'added by me' ? 'created=true' : ((tag === 'all') ? '' : `difficulty=${tag}`)}&page=${page}&title=${search}`, (data: any) => {

                        setMcq(data.mcqs)
                    }, (err: any) => {
                        console.log(err)
                    })
                }, (err) => {

                })
            })
        }
    }

    useEffect(() => {

        // !document.cookie.includes('lgdin') && navigate('/login')

        const api = `${process.env.REACT_APP_API}problem/all?${tag === 'added by me' ? 'created=true' : ((tag === 'all') ? '' : `difficulty=${tag}`)}&tags=${topic.join(',')}&page=${page}&title=${search}`
        const api2 = `${process.env.REACT_APP_API}mcq/all?${tag === 'added by me' ? 'created=true' : ((tag === 'all') ? '' : `difficulty=${tag}`)}&tags=${topic.join(',')}&page=${page}&title=${search}`
        getRequest(api, (data: any) => {

            setQuestionArr(data.problems)
        }, (err: any) => {
            console.log(err)
        })
        getRequest(api2, (data: any) => {

            setMcq(data.mcqs)
        }, (err: any) => {
            console.log(err)
        })
    }, [tag, page, search,topic])


    return (
        <div className={`w-full overflow-hidden  h-auto border-2 border-bleachBlue rounded-lg mt-12 bg-[#F2F6FC]`}>
            <div className={'px-5 py-4 flex justify-between items-center border-b-2 border-bleachBlue'}>
                <span className=' border-inputBorder rounded-lg border flex'>

                    <input value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder='search questions' className=' rounded-lg font-manrope text-lg text-textGrey px-3 py-2 focus:outline-none' />
                    <button className='px-3 text-textGrey'>
                        search
                    </button>
                </span>
                {/* Total questions added : <span className='text-highlightText'>
                        {type === 'coding' ? questionArr.length : mcq.length}
                    </span>
                </span> */}
                <div className='flex gap-3 items-center'>
                    {!deletion && <DropDown editable={true} displayText={`Sort by : ${tag}`} array={filter} onComponentClick={onFilterChange} />}
                    {deletion && <PrimaryButton onClick={deleteSelected}>
                        Delete Selected
                    </PrimaryButton>}
                    <SecondaryButtons classes='!py-2 !h-min !px-3 !text-xs' onClick={deleteQuestions}>
                        {deletion ? 'Cancel delete' : 'Delete questions'}
                    </SecondaryButtons>
                </div>
            </div>
            <div className='bg-white'>

                <div className={`w-full flex `}>
                    <div onClick={() => { setType('coding') }} className={` w-1/2 rounded-r-xl text-center font-montserrat text-lg py-3 text-darkBlue font-medium ${type === 'coding' ? 'bg-[#f9f9f9] text-highlightText' : 'bg-[#EDF2FC]'}`}>
                        Coding Question
                    </div>
                    <div onClick={() => { setType('mcq') }} className={` w-1/2 rounded-l-xl text-center font-montserrat text-lg py-3 text-darkBlue font-medium ${type === 'mcq' ? 'bg-[#f9f9f9] text-highlightText' : 'bg-[#EDF2FC]'}`}>
                        MCQ
                    </div>
                </div>
                <div className='flex gap-2 overflow-auto h-min px-3 py-3'>
                    {tagsHardArr.map((tags: any, i: number) => {
                        if (i < 8) {
                            return <span
                                key={`tags-${i}`}
                                onClick={() => addTag(tags)}
                                className={`${topic.includes(tags) ? 'bg-highlightText text-white' : 'bg-white text-highlightText'} 
                            py-3 px-3 w-fit whitespace-nowrap h-min font-poppins border text-xs
                            border-highlightText rounded-full cursor-pointer`}>
                                {tags}</span>
                        }
                    })}
                    <span
                        onClick={() => setTagOpen(true)}
                        className={`text-white bg-highlightText 
                            py-3 px-3 w-fit whitespace-nowrap h-min font-poppins border text-xs
                            border-highlightText rounded-full cursor-pointer`}>
                        More.... </span>
                </div>
                <div className='flex flex-col gap-1 px-2 min-h-[50vh]'>
                    {type === 'coding' ? (
                        questionArr.map((questions: any, i: number) => {
                            return <div
                                key={`${questions}${Math.floor(Math.random() * 10000)} `}
                                className='flex items-center'>
                                {deletion && <div onClick={() => addToDelete(questions.id)} className={`h-4 w-4 border-[1.5px] border-inputBorder rounded-sm ${arrayDelete.includes(questions.id) ? 'bg-highlightText' : 'bg-white'}`}>
                                    {arrayDelete.includes(questions.id) && <svg
                                        fill="none"
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={3}
                                        viewBox="0 0 24 24"
                                        height="0.8em"
                                        width="0.8em"
                                    >
                                        <path d="M20 6L9 17l-5-5" />
                                    </svg>}
                                </div>}
                                <QuestionListing
                                    questionType='coding'

                                    index={(i + page * 10) - 10}
                                    type={'homepage'}
                                    data={questions}
                                    onClick={() => { }} />
                            </div>
                        })
                    ) : (
                        mcq?.map((questions: any, i: number) => {
                            return <div
                                key={`${questions}${Math.floor(Math.random() * 10000)} `}
                                className='flex items-center'>
                                {deletion && <div onClick={() => addToDeleteMcq(questions.id)} className={`h-4 w-4 border-[1.5px] border-inputBorder rounded-sm ${mcqDelete.includes(questions.id) ? 'bg-highlightText' : 'bg-white'}`}>
                                    {mcqDelete.includes(questions.id) && <svg
                                        fill="none"
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={3}
                                        viewBox="0 0 24 24"
                                        height="0.8em"
                                        width="0.8em"
                                    >
                                        <path d="M20 6L9 17l-5-5" />
                                    </svg>}
                                </div>}
                                <QuestionListing
                                    questionType='mcq'
                                    index={(i + page * 10) - 10}
                                    type={'homepage'}
                                    data={questions}
                                    onClick={() => { }} />
                            </div>
                        })
                    )}
                </div>



            </div>
            <div >
                <div className='w-1/2 mx-auto flex justify-between items-center my-3'>
                    <button
                        disabled={page === 1 && true}
                        className={` p-2 rounded-full border-slate-700 border shadow-lg ${page === 1 && 'text-inputBorder'}`}
                        onClick={() => {
                            if (page === 1) {

                            }
                            else {
                                setPage(page - 1)
                            }
                        }}>

                        <svg
                            className='rotate-90'
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.5886 7.74408C5.26317 7.41864 4.73553 7.41864 4.41009 7.74408C4.08466 8.06951 4.08466 8.59715 4.41009 8.92259L9.41009 13.9226C9.73553 14.248 10.2632 14.248 10.5886 13.9226L15.5886 8.92259C15.914 8.59715 15.914 8.06951 15.5886 7.74408C15.2632 7.41864 14.7355 7.41864 14.4101 7.74408L9.99935 12.1548L5.5886 7.74408Z"
                                fill="currentColor" />
                        </svg>
                    </button>

                    <div>
                        <span>
                            {page}
                        </span>
                    </div>

                    <button className={` p-2 rounded-full border-slate-700 border shadow-lg
                    ${((type === 'coding' && (questionArr && questionArr.length < 10)) || (type === 'mcq' && (mcq && mcq.length < 10))) && 'text-inputBorder'}
                    `}
                        onClick={() => {
                            if ((type === 'coding' && (questionArr && questionArr.length === 10)) || (type === 'mcq' && (mcq && mcq.length === 10))) {

                                setPage(page + 1)
                            }

                        }}>

                        <svg
                            className='-rotate-90'
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.5886 7.74408C5.26317 7.41864 4.73553 7.41864 4.41009 7.74408C4.08466 8.06951 4.08466 8.59715 4.41009 8.92259L9.41009 13.9226C9.73553 14.248 10.2632 14.248 10.5886 13.9226L15.5886 8.92259C15.914 8.59715 15.914 8.06951 15.5886 7.74408C15.2632 7.41864 14.7355 7.41864 14.4101 7.74408L9.99935 12.1548L5.5886 7.74408Z"
                                fill="currentColor" />
                        </svg>

                    </button>
                </div>
            </div>
            {tagOpen && <div className='fixed top-0 left-0 w-screen h-screen backdrop-blur-md flex justify-center items-center '>
                <div className='bg-white rounded-lg px-3 py-3 flex flex-wrap overflow-auto space-x-2 space-y-1 w-1/2 h-1/2'>
                    <div className='w-full h-auto flex justify-between px-3 my-3'>
                        <div className='text-darkBlue font-montserrat font-medium text-lg capitalize'>
                            Tags
                        </div>
                        <div onClick={() => setTagOpen(false)} className='font-manrope font-bold text-textGrey text-xl cursor-pointer'>x</div>
                    </div>
                    {tagsHardArr.map((tags: any, i: number) => {
                        return <span
                            key={`tags-${i}`}
                            onClick={() => addTag(tags)}
                            className={`${topic.includes(tags) ? 'bg-highlightText text-white' : 'bg-white text-highlightText'} 
                            py-3 px-3 w-fit h-min font-poppins border text-sm
                            border-highlightText rounded-full cursor-pointer`}>
                            {tags}</span>
                    })}
                </div>
            </div>}
        </div>
    )
}

export default HomePageListing
