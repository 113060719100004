import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getRequest } from '../ApiFunctions.tsx/get'

const ViewExam = () => {
    const navigate = useNavigate()
    const [exams, setExams] = useState<any>([])
    const [pageNo, setPageNo] = useState<number>(1)
    useEffect(() => {
        getRequest(`${process.env.REACT_APP_API}exam/all?page=${pageNo}`, (data) => {
            setExams(data)
        }, (err) => {
            console.log(err)
        })
    }, [pageNo])
    return (
        <div className='w-full p-12 h-auto min-h-screen bg-gradient-to-br from-white to-indigo-100'>
            <div className='pl-10 pr-6 pt-2.5 pb-1 bg-white shadow-sm min-h-[80vh] h-auto w-full rounded-lg'>
                <p className='mb-4 '>
                    <svg onClick={() => navigate('/')} className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 24 21" fill="none">
                        <path d="M23.2503 10.0001C23.2503 9.50282 23.0528 9.02591 22.7011 8.67428C22.3495 8.32265 21.8726 8.1251 21.3753 8.1251L6.5253 8.1251L11.4503 3.2001C11.7815 2.84467 11.9618 2.37455 11.9532 1.8888C11.9447 1.40305 11.7479 0.939583 11.4044 0.59605C11.0608 0.252518 10.5974 0.0557365 10.1116 0.0471649C9.62586 0.0385952 9.15574 0.218904 8.8003 0.550106L0.675303 8.6751C0.324177 9.02667 0.126953 9.50323 0.126953 10.0001C0.126953 10.497 0.324177 10.9735 0.675303 11.3251L8.8003 19.4501C8.97196 19.6343 9.17896 19.7821 9.40896 19.8846C9.63896 19.987 9.88724 20.0421 10.139 20.0466C10.3908 20.051 10.6408 20.0047 10.8743 19.9104C11.1078 19.8161 11.3199 19.6757 11.4979 19.4977C11.6759 19.3197 11.8163 19.1076 11.9106 18.8741C12.0049 18.6406 12.0512 18.3906 12.0468 18.1388C12.0423 17.887 11.9872 17.6388 11.8848 17.4088C11.7823 17.1788 11.6345 16.9718 11.4503 16.8001L6.5253 11.8751L21.3753 11.8751C22.4103 11.8751 23.2503 11.0351 23.2503 10.0001Z" fill="#01044F" />
                    </svg>
                </p>
                <div className='mb-8'>

                    <span className='mx-auto text-darkBlue border-b-2 border-highlightText p-2 font-montserrat font-semibold text-2xl'>
                        Exams
                    </span>
                </div>

                <div className='flex flex-col gap-2 px-1'>
                    {exams && exams.map((exam: any, i: number) => {
                        return <div key={`exam${exam.title}`} className='flex justify-between bg-white w-full  h-auto py-3 px-5 shadow-sm  shadow-[#EEF3FC] rounded-lg mx-auto'>
                           {(i+pageNo*10)-9}. &nbsp;{exam.title}
                            <button className='w-auto text-xs font-montserrat rounded-full border border-highlightText text-highlightText px-4 py-1' 
                            onClick={() => {navigate(`/view-exam/${exam.id}`) }}>
                                view and schedule
                            </button>
                        </div>
                    })}
                    <div >
                        <div className='w-1/2 mx-auto flex justify-between items-center my-3'>
                            <button
                                disabled={pageNo === 1 && true}
                                className={` p-2 rounded-full border-slate-700 border shadow-lg ${pageNo === 1 && 'text-inputBorder'}`}
                                onClick={() => {
                                    if (pageNo === 1) {

                                    }
                                    else {
                                        setPageNo(pageNo - 1)
                                    }
                                }}>

                                <svg
                                    className='rotate-90'
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M5.5886 7.74408C5.26317 7.41864 4.73553 7.41864 4.41009 7.74408C4.08466 8.06951 4.08466 8.59715 4.41009 8.92259L9.41009 13.9226C9.73553 14.248 10.2632 14.248 10.5886 13.9226L15.5886 8.92259C15.914 8.59715 15.914 8.06951 15.5886 7.74408C15.2632 7.41864 14.7355 7.41864 14.4101 7.74408L9.99935 12.1548L5.5886 7.74408Z"
                                        fill="currentColor" />
                                </svg>
                            </button>

                            <div>
                                <span>
                                    {pageNo}
                                </span>
                            </div>

                            <button className={` p-2 rounded-full border-slate-700 border shadow-lg
                    ${exams.length < 10 && 'text-inputBorder'}
                    `}
                                onClick={() => {
                                    if (exams.length === 10) {

                                        setPageNo(pageNo + 1)
                                    }

                                }}>

                                <svg
                                    className='-rotate-90'
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M5.5886 7.74408C5.26317 7.41864 4.73553 7.41864 4.41009 7.74408C4.08466 8.06951 4.08466 8.59715 4.41009 8.92259L9.41009 13.9226C9.73553 14.248 10.2632 14.248 10.5886 13.9226L15.5886 8.92259C15.914 8.59715 15.914 8.06951 15.5886 7.74408C15.2632 7.41864 14.7355 7.41864 14.4101 7.74408L9.99935 12.1548L5.5886 7.74408Z"
                                        fill="currentColor" />
                                </svg>

                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewExam
