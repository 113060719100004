import React, { useEffect, useState } from 'react'
import { DropDownLanguage } from '../components/DropDownLanguage'
import { languagesArr } from '../components/GlobalConst'
import { Editor } from '@monaco-editor/react'
import { PrimaryButton, SecondaryButtons } from '../components/Buttons'
import { useNavigate, useParams } from 'react-router-dom'
import { getRequest } from '../ApiFunctions.tsx/get'
import { postRequest } from '../ApiFunctions.tsx/post'
import { putRequest } from '../ApiFunctions.tsx/put'

const AddStarterCode = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [language, setLanguage] = useState<any>(languagesArr[0])
    const [boilerplate, setBoilerplate] = useState<string>('')
    const [starterCodes, setStarterCodes] = useState<any>([])


    const setEditorTheme = (monaco: any) => {
        monaco.editor.defineTheme('customLightInput', {
            base: 'vs',
            inherit: true,
            rules: [{}],
            colors: {
                "editor.background": '#Ebebeb',
            },
        });
        monaco.editor.defineTheme('customDark', {
            base: 'vs-dark',
            inherit: true,
            rules: [{}],
            colors: {
                "editor.background": '#1a2033',
            },
        });

    }


    const submitStarterCode = () => {
        if (boilerplate === '') {
            window.alert('all fields are required')
        }

        const exist = starterCodes.find((starterCode: any) => starterCode.language_id === language.id)
        if (exist !== undefined) {
            const data = {
                language_id: language.id,
                code: btoa(boilerplate)
            }
            // /problem/:id/editboilerplate/:boilerplateid
            putRequest(`${process.env.REACT_APP_API}problem/${id}/editboilerplate/${exist.id}`, data, (data: any) => {
                window.alert('starter code added successfully')
            }, (err: any) => {
                console.log(err)
            })
        }
        else {
            const data = {
                boilerplates: [
                    {
                        language_id: language.id,
                        code: btoa(boilerplate)
                    }
                ]
            }
            postRequest(`${process.env.REACT_APP_API}problem/${id}/addboilerplate`, data, (data: any) => {
                window.alert('starter code added successfully')
            }, (err: any) => {
                console.log(err)
            })
        }
    }

    useEffect(() => {
        getRequest(`${process.env.REACT_APP_API}problem/${id}/boilerplates`, (data: any) => {
            setStarterCodes(data)
        }, (err: any) => {
            console.log(err)
        })
    }, [])


    return (
        <div className={`bg-gradient-to-br  from-white to-[#DFEAFD] min-h-screen w-full h-auto py-8 px-24 `}>
            <div className='bg-white rounded-lg w-full h-auto min-h-[85vh] px-12 py-9'>
                <div className='w-full flex justify-center items-center mb-4 '>

                    <span className='mx-auto text-darkBlue border-b-2 border-highlightText p-2 font-montserrat font-semibold text-2xl'>
                        Starter code
                    </span>
                </div>
                <div className='w-min'>

                    <DropDownLanguage displayText={`language : ${language.name.split(' (')[0]}`} onComponentClick={(data: any) => setLanguage(data)} editable={true} />
                </div>
                <div className=' mt-3 rounded-lg overflow-hidden h-auto w-auto'>
                    <Editor
                        width={'100%'}
                        height={'50vh'}
                        language={language && language.name.split(' (')[0].toLowerCase()}

                        value={boilerplate}
                        onChange={(value: any, e: any) => setBoilerplate(value)}
                        beforeMount={setEditorTheme}
                        theme={'customLightInput'}
                    />
                </div>
                <p className=' mt-5 '>

                    <SecondaryButtons onClick={() => { navigate(`/viewstartercode/${id}`) }}>
                        View Added Starter code
                    </SecondaryButtons>
                </p>
            </div>
            <div className='w-fit mx-auto mt-4'>

                <PrimaryButton onClick={submitStarterCode}>
                    submit
                </PrimaryButton>
            </div>
        </div>
    )
}

export default AddStarterCode
