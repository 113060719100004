import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getRequest } from '../ApiFunctions.tsx/get'
import { deleteRequest } from '../ApiFunctions.tsx/delete'
import { PrimaryButton, SecondaryButtons } from '../components/Buttons'
import { postRequest } from '../ApiFunctions.tsx/post'
import { useUserData } from '../components/useUserData'

const ExamDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { designation } = useUserData()
    const [examDetail, setExams] = useState<any>()
    const [schedules, setSchedules] = useState<any>([])
    const [modal, setModal] = useState<boolean>(false)
    const [start, setStart] = useState<any>('')
    const [end, setEnd] = useState<any>('')
    const [userId, setUserId] = useState<any>('')

    const deleteExam = () => {
        const api = `${process.env.REACT_APP_API}exam/${id}`
        window.confirm('are you sure you want to delete this exam?') && deleteRequest(api, () => {
            navigate(`/view-exam`)
        }, (err) => {
            console.log(err)
            window.alert('exam could not be deleted')
        })

    }

    const schedule = () => {

        const data = {
            examId: id,
            start,
            end
        }
        if (start === '' || end === '') {
            window.alert('start time and end time cannot be empty')
        }
        else {

            postRequest(`${process.env.REACT_APP_API}exam/schedule`, data, (data) => {
                window.alert('exam scheduled successfully')
                navigate('/')
            }, (err) => {
                console.log(err)
            })
        }
    }




    useEffect(() => {
        const cookieId: any = document.cookie.split(';').find((c) => c.includes('ustid'))?.replace(' ustid=', '')
        setUserId(atob(cookieId || ''))

        getRequest(`${process.env.REACT_APP_API}exam/${id}`, (data) => {
            setExams(data)
            getRequest(`${process.env.REACT_APP_API}exam/schedules/${id}`, (data) => {
                setSchedules(data)
            }, (err) => {
                console.log(err)
            })
        }, (err) => {
            console.log(err)
        })
    }, [id])

    //     "id": "118774871073792",
    //     "title": "Exam on Python",
    //     "problems": [
    //         {
    //             "marks": 10,
    //             "problemId": "19514465901568"
    //         },
    //         {
    //             "marks": 10,
    //             "problemId": "19514453318656"
    //         },
    //         {
    //             "marks": 10,
    //             "problemId": "19514444930048"
    //         },
    //         {
    //             "marks": 10,
    //             "problemId": "1304143715488768"
    //         },
    //         {
    //             "marks": 10,
    //             "problemId": "2433928794112"
    //         }
    //     ],
    //     "duration": 60,
    //     "instructions": "jfj.wfewkfj jhk",
    //     "creatorId": "86483465788416",
    //     "createdAt": "2023-12-29T18:44:41.371Z",
    //     "updatedAt": "2023-12-29T18:44:41.371Z"
    // }
    return (
        <div className={`w-full p-12 h-auto min-h-screen bg-gradient-to-br from-white to-indigo-100  ${modal && 'h-[80vh] overflow-hidden'}`}>
            <div className='p-10 bg-white shadow-sm min-h-[80vh] h-auto w-full rounded-lg'>
                <p className='mb-4 flex justify-between '>
                    <svg onClick={() => navigate('/view-exam')} className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 24 21" fill="none">
                        <path d="M23.2503 10.0001C23.2503 9.50282 23.0528 9.02591 22.7011 8.67428C22.3495 8.32265 21.8726 8.1251 21.3753 8.1251L6.5253 8.1251L11.4503 3.2001C11.7815 2.84467 11.9618 2.37455 11.9532 1.8888C11.9447 1.40305 11.7479 0.939583 11.4044 0.59605C11.0608 0.252518 10.5974 0.0557365 10.1116 0.0471649C9.62586 0.0385952 9.15574 0.218904 8.8003 0.550106L0.675303 8.6751C0.324177 9.02667 0.126953 9.50323 0.126953 10.0001C0.126953 10.497 0.324177 10.9735 0.675303 11.3251L8.8003 19.4501C8.97196 19.6343 9.17896 19.7821 9.40896 19.8846C9.63896 19.987 9.88724 20.0421 10.139 20.0466C10.3908 20.051 10.6408 20.0047 10.8743 19.9104C11.1078 19.8161 11.3199 19.6757 11.4979 19.4977C11.6759 19.3197 11.8163 19.1076 11.9106 18.8741C12.0049 18.6406 12.0512 18.3906 12.0468 18.1388C12.0423 17.887 11.9872 17.6388 11.8848 17.4088C11.7823 17.1788 11.6345 16.9718 11.4503 16.8001L6.5253 11.8751L21.3753 11.8751C22.4103 11.8751 23.2503 11.0351 23.2503 10.0001Z" fill="#01044F" />
                    </svg>
                    <div className='w-full flex justify-end'>
                        {(examDetail?.creatorId === userId ||designation==='admin')&& <PrimaryButton onClick={deleteExam}>
                            Delete Exam
                        </PrimaryButton>}
                    </div>
                </p>
                <div className='mb-8'>

                    <span className='mx-auto text-darkBlue border-b-2 border-highlightText p-2 font-montserrat font-semibold text-2xl'>
                        {examDetail && examDetail.title}
                    </span>
                </div>
                <div className='flex mb-5'>
                    <div className='font-manrope text-textBlack font-medium text-xl'>
                        Total questions : <span className='font-manrope text-highlightText font-medium text-xl'>
                            {examDetail && examDetail.problems.length}
                        </span>
                    </div>

                </div>
                <div className='w-[50vw] min-h-[30vh] h-auto bg-gradient-to-r from-[#ebebeb] to-[#d9d9d900] rounded-lg px-4 py-3'>
                    <span className='text-indigo-950 text-2xl font-medium font-manrope'>
                        Instructions :
                    </span>
                    <p className='text-textGrey font-manrope '>
                        {examDetail && examDetail.instructions}
                    </p>
                </div>
                <div className='flex my-5'>
                    <div className='font-manrope text-textBlack font-medium text-xl'>
                        Total Duration : <span className='font-manrope text-highlightText font-medium text-xl'>
                            {examDetail && examDetail.duration}
                        </span>
                    </div>

                </div>
                <div className='flex my-5'>
                    <div className='font-montserrat text-darkBlue font-medium text-2xl'>
                        Schedules
                    </div>

                </div>
                <div className='flex flex-col gap-1 px-1'>
                    {
                        schedules && schedules.map((schedule: any) => {
                            return <div className='flex justify-between items-center h-auto w-full bg-whitesky shadow-sm shadow-[#EEF3FC] rounded-lg p-2'>
                                <div className='flex flex-col gap-3'>
                                    <span className='text-darkBlue text-xl font-medium capitalize'>
                                        start :
                                        <span className='text-textGrey font0normal font-manrope'>
                                            {new Date(schedule.start).toDateString()} &nbsp; {new Date(schedule.start).toTimeString()}
                                        </span>
                                    </span>
                                    <span className='text-darkBlue text-xl font-medium capitalize'>
                                        end :
                                        <span className='text-textGrey font0normal font-manrope'>
                                            {new Date(schedule.end).toDateString()}&nbsp; {new Date(schedule.end).toTimeString()}
                                        </span>
                                    </span>
                                </div>
                                {<div onClick={() => { navigate(`/schedule-view/${schedule.id}`) }} className='text-xs rounded-full px-4 py-2 text-highlightText border border-highlightText'>
                                    View More
                                </div>}
                                {/* {atob((document.cookie.split(';').filter((val) => val.includes('ustid'))[0]).replace('ustid=', '')).includes(`${schedule.creatorId}`) && <div
                                    onClick={() => deleteExamSchedule(schedule.id)}
                                    className='w-min'
                                >
                                    <span className='text-red-400'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M15.1464 4.35355L15.2929 4.5H15.5H18.5V5.5H5.5V4.5H8.5H8.70711L8.85355 4.35355L9.70711 3.5H14.2929L15.1464 4.35355ZM8 20.5C7.17614 20.5 6.5 19.8239 6.5 19V7.5H17.5V19C17.5 19.8239 16.8239 20.5 16 20.5H8Z"
                                                stroke="currentColor" />
                                        </svg>
                                    </span>
                                </div>
                                } */}
                            </div>
                        })
                    }

                </div>
            </div>
            <div className='w-fit mx-auto mt-3'>

                <PrimaryButton onClick={() => { setModal(true) }}>
                    Schedule
                </PrimaryButton>
            </div>
            {modal && <div className='absolute top-0 left-0 w-full h-screen backdrop-blur-md flex justify-center items-center overflow-hidden'>
                <div className='bg-white w-[60vw] h-auto min-h-[50vh] rounded-lg flex flex-col px-24 justify-evenly items-center'>
                    <span className='mb-8 mx-auto text-darkBlue border-b-2 border-highlightText p-2 font-montserrat font-semibold text-2xl'>
                        Schedule
                    </span>
                    <div className='w-fit font-montserrat text-xl font-semibold flex gap-3 items-center'>
                        Start:  <input
                            className='text-base text-textBlack font-manrope mx-5 py-3 px-2 border border-inputBorder'
                            type="datetime-local"
                            // value={date}
                            placeholder="DD/MM/YY"
                            onChange={(e) => setStart(new Date(e.target.value))}
                        />
                    </div>
                    <div className='w-fit font-montserrat text-xl font-semibold flex gap-3 items-center'>
                        End :  <input
                            className='text-base text-textBlack font-manrope mx-5 py-3 px-2 border border-inputBorder'
                            type="datetime-local"
                            // value={date}
                            placeholder="DD/MM/YY"
                            onChange={(e) => setEnd(new Date(e.target.value))}
                        />
                    </div>
                    <div className='flex gap-3'>
                        <SecondaryButtons onClick={() => setModal(false)}>
                            Cancel
                        </SecondaryButtons>
                        <PrimaryButton onClick={schedule}>
                            Confirm
                        </PrimaryButton>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default ExamDetail
