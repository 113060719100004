export const useUserData = () => {
    const data = localStorage.getItem('user')
    if (data) {
        const user = JSON.parse(data)
      
        return {
            userId: user.id,
            userName: user.name,
            designation: user.designation,
            email: user.email,
        }
    }
    else {
        return {
            userId: '',
            userName: '',
            designation: '',
            email: '',
        }
    }
}