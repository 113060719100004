import React, { useEffect, useState } from 'react'
import { getRequest } from '../ApiFunctions.tsx/get'
import { useParams } from 'react-router-dom'
import BoilerPlateComponent from '../components/examPortal/BoilerPlateComponent'

const ViewStarterCode = () => {
    const {id}=useParams()
    const [boilerplates, setBoilerplates] = useState<any>([])

    useEffect(() => {
        getRequest(`${process.env.REACT_APP_API}problem/${id}/boilerplates`,(data:any)=>{
            setBoilerplates(data)
        },(err:any)=>{
            console.log(err)
        })
    }, [id])

    return (
        <div className={`bg-gradient-to-br from-white to-[#DFEAFD] min-h-screen w-full h-auto py-8 px-24`}>
            <div className='bg-white rounded-lg w-full h-auto min-h-[85vh] px-12 py-9'>
                <div className='w-full flex justify-center items-center mb-4 '>
                    <span className='mx-auto text-darkBlue border-b-2 border-highlightText p-2 font-montserrat font-semibold text-2xl'>
                        Added Starter code
                    </span>
                </div>
                {boilerplates.length===0?(
                    <div className='w-full h-full flex justify-center items-center text-textGrey font-montserrat font-medium text-lg'>
                        No startercode available
                    </div>
                ):(
                    <div className='w-full h-auto flex-col gap-3 '>
                        {boilerplates.map((boilerplate:any,index:any)=>{
                                return <BoilerPlateComponent key={`startercodelist${boilerplate.id}${index}`} data={boilerplate} />
                        })}
                    </div>
                )}

            </div>
        </div>
    )
}

export default ViewStarterCode
