import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

type questionList = {
    type: string,
    data: any,
    onClick: any,
    index: number,
    questionType?: string,
    setQuestions: any
}


const ExamListing = (props: questionList) => {
    const navigate = useNavigate()
    const [added, setAdded] = useState(false)



    const addQuestion = () => {
        const obj = localStorage.getItem('exam-question')


        if (obj) {
            const questionObj = JSON.parse(obj)
            const index = questionObj.questions.findIndex((question: any) => {
                return question.id === props.data.id
            })
            if (index !== -1) {
                questionObj.questions.splice(index, 1)
                localStorage.setItem('exam-question', JSON.stringify(questionObj))
                props.setQuestions(questionObj)
                setAdded(false)
            }
            else {
                const data = {
                    type: props.type === 'coding' ? 'problem' : 'mcq',
                    id: props.data.id,
                    title: props.data.title,
                    difficulty: props.data.difficulty
                }
                questionObj.questions.push(data)
                localStorage.setItem('exam-question', JSON.stringify(questionObj))
                props.setQuestions(questionObj)
                setAdded(true)
            }
        }
        else {
            const data = {
                questions: [
                    {
                        type: props.type === 'coding' ? 'problem' : 'mcq',
                        id: props.data.id,
                        title: props.data.title,
                        difficulty: props.data.difficulty
                    }
                ]
            }
            localStorage.setItem('exam-question', JSON.stringify(data))
            props.setQuestions(data)
        }
        // {
        //     "title":"Second Exam",
        //     "problems":[
        //         {
        //             "type":"problem",
        //             "id":1463922402304,
        //             "marks":2
        //         },
        //         {
        //             "type":"problem",
        //             "id":815078863872,
        //             "marks":1
        //         },
        //         {
        //             "type":"mcq",
        //             "id":21280584704,
        //             "marks":1
        //         }
        //     ],
        //     "instructions":"xyz",
        //     "duration": 90
        // }

    }


    useEffect(() => {

        const obj = localStorage.getItem('exam-question')


        if (obj) {
            const questionObj = JSON.parse(obj)
            const index = questionObj.questions.findIndex((question: any) => {
                return question.id === props.data.id
            })
            if (index !== -1) {
                setAdded(true)
                console.log(index)
            }
            else {
                setAdded(false)
                console.log(index)
            }
        }

    }, [props])

    return (
        <div
            className='flex justify-between w-full bg-white text-base font-manrope font-medium text-textGrey rounded-lg shadow-md shadow-[#EEF3FC] p-3'>
            <div
                onClick={() => props.questionType === 'coding' ? navigate(`/viewcq/${props.data.id}`) : navigate(`/viewmcq/${props.data.id}`)}
                className='text-base w-full cursor-pointer overflow-clip whitespace-nowrap text-ellipsis'>
                {props.index + 1}. {props.data.title}
            </div>

            <div className='w-min '>

                <div className='w-auto flex justify-between items-center gap-3'>
                    <span className={`font-medium font-manrope text-base uppercase 
                        ${props.data.difficulty === 'easy' ? 'text-[#2EA6A3]' : (props.data.difficulty === 'medium' ? 'text-[#FBDD8D]' : 'text-[#FF8E96]')}`}>
                        {props.data.difficulty.split('')[0]}
                    </span>
                    <button
                        onClick={addQuestion}
                        className={`${added ? 'text-green-500 border-green-500' : 'text-highlightText border-highlightText'}  text-xs rounded-full w-max py-1 px-[0.65rem] border  gap-1 flex items-center`}>
                        {added ?
                            <span className='mr-1 flex'>
                                Added
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                                    <path d="M12.2493 4.72875L5.24935 11.7287L2.04102 8.52042L2.86352 7.69792L5.24935 10.0779L11.4268 3.90625L12.2493 4.72875Z" fill="#33BA69" />
                                </svg>
                            </span>
                            : 'Add +'}
                    </button>
                </div>

            </div>
        </div>
    )
}

export default ExamListing