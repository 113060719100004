import React, { useEffect, useState } from 'react'
import { PrimaryButton, SecondaryButtons } from '../components/Buttons'
import Modal from '../components/Modal'
import { useNavigate } from 'react-router-dom'
import { postRequest } from '../ApiFunctions.tsx/post'

const CreateExamConfirm = () => {
    const navigate=useNavigate()
    const [question, setQuestion] = useState<any>({ questions: [] })
    const [finalProblems, setProblems] = useState<any[]>([])
    const [title, setTitle] = useState<string>('')
    const [instructions, setInstructions] = useState<string>('')
    const [duration, setDuration] = useState<number>()
    const [open, setOpen] = useState<boolean>(false)


    const confirm=()=>{
        if(title===''||(duration&&duration<=0)||!duration||typeof duration !== 'number'||instructions===''){
            window.alert('all fields are necessary')
        }
        else{
            const api = process.env.REACT_APP_API + 'exam/create'
            const data = {
                title,
                instructions,
                duration,
                problems: finalProblems
            }
            return postRequest(api, data, (data) => {
                localStorage.removeItem('exam-question')
                setOpen(true)
    
            }, (err) => {
                console.log(err)
            })
        }
       
    }


    const submit = () => {
        let obj = []
        for (let i = 0; i < question.questions.length; i++) {
            if ((document.getElementById(`marks${i}`) as HTMLInputElement).value === '') {
                window.alert('Please enter marks')
                break;
            }
            const problemObj = {
                type: question.questions[i].type,
                problemId: question.questions[i].id,
                marks: parseInt((document.getElementById(`marks${i}`) as HTMLInputElement).value)
            }
            obj.push(problemObj)
        }
        if (obj.length === question.questions.length) {
            setProblems(obj)
            console.log(obj)
        }
    }
    useEffect(() => {
        const obj = localStorage.getItem('exam-question')


        if (obj) {
            const questionObj = JSON.parse(obj)
            setQuestion(questionObj)
            console.log(questionObj)
        }
    }, [])

    return (
        <div className='bg-gradient-to-br from-white to-[#DFEAFD] px-14 py-10 pb-16 min-h-screen h-auto w-full flex flex-col' >
            
            {finalProblems[0] ? <>
                <div className='bg-white rounded-lg w-full h-full min-h-[85vh] p-3'>
                    <div className='flex flex-col gap-4 '>
                        <input value={title} onChange={(e) => setTitle(e.target.value)} className='focus:outline-none w-full text-lg text-darkBlue font-semibold font-montserrat bg-[#ebebeb] rounded-lg p-4' placeholder='Enter a title' />
                        <textarea value={instructions} onChange={(e) => setInstructions(e.target.value)} className='focus:outline-none resize-none h-[45vh] text-textGrey w-full text-lg font-medium bg-[#ebebeb] rounded-lg p-4' placeholder='Enter Instructions' />
                        <input value={duration} onChange={(e) => setDuration(parseInt(e.target.value))} type={'number'} className='focus:outline-none w-full text-textGrey text-lg font-medium bg-[#ebebeb] rounded-lg p-4' placeholder='Enter Duration (in minutes)' />
                    </div>
                    <div className='w-full flex justify-center items-center h-auto py-3 mx-auto mt-auto '>
                        <div className='w-min flex gap-3'>
                            <SecondaryButtons onClick={()=>{setProblems([])}}>
                                Cancel
                            </SecondaryButtons>
                            <PrimaryButton onClick={confirm}>
                                Confirm
                            </PrimaryButton>
                        </div>
                    </div>
                    <Modal  open={open} setOpen={setOpen} onClick={() => { navigate('/') } } text={'Exam created successfully'}/>
                </div>
            </> : <>
                <div className='bg-white rounded-lg w-full h-full min-h-[85vh]'>
                    <div className='mb-8 p-4'>

                        <span className='mx-auto text-darkBlue border-b-2 border-highlightText p-2 font-montserrat font-semibold text-2xl'>
                            Added Questions
                        </span>
                    </div>
                    <div className='flex flex-col gap-1 px-2'>
                        {question.questions[0] && question.questions.map((data: any, i: number) => {
                            return <div
                                className='flex justify-between w-full bg-white text-base font-manrope font-medium text-textGrey rounded-lg shadow-md shadow-[#EEF3FC] p-3'>
                                <div
                                    className='text-base w-full cursor-pointer overflow-clip whitespace-nowrap text-ellipsis'>
                                    {i + 1}. {data.title}
                                </div>

                                <div className='w-min '>

                                    <div className='w-auto flex justify-between items-center gap-3'>
                                        <span className={`font-medium font-manrope text-base uppercase 
                                    ${data.difficulty === 'easy' ? 'text-[#2EA6A3]' : (data.difficulty === 'medium' ? 'text-[#FBDD8D]' : 'text-[#FF8E96]')}`}>
                                            {data.difficulty.split('')[0]}
                                        </span>
                                        <input
                                            type='number'
                                            id={`marks${i}`}
                                            placeholder='marks'
                                            className={`text-highlightText text-center  border-highlightText text-xs rounded-full w-20 focus:outline-none py-1 px-[0.65rem] border  gap-1 flex items-center`} />

                                    </div>

                                </div>
                            </div>
                        })}
                    </div>
                </div>
                <div className='w-full flex justify-center items-center h-auto py-3 bg-gradient-to-b from-[#ffffff] to-[#ececec] mx-auto mt-auto '>
                    <div className='w-min flex gap-3'>
                        <SecondaryButtons onClick={()=>{ localStorage.removeItem('exam-question');navigate('/create-exam')}}>
                            Cancel
                        </SecondaryButtons>
                        <PrimaryButton onClick={submit}>
                            Submit
                        </PrimaryButton>
                    </div>
                </div>
            </>}
        </div>
    )
}

export default CreateExamConfirm
