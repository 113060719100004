import React from 'react'
import { useNavigate } from 'react-router-dom'

type questionList = {
    type: string,
    data: any,
    onClick: any,
    index: number,
    questionType?:string
}
const QuestionListing = (props: questionList) => {
    const navigate=useNavigate()
    return (
        <div
        className='flex justify-between w-full bg-white text-base font-manrope font-medium text-textGrey rounded-lg shadow-md shadow-[#EEF3FC] p-3'>
            <div className='text-base w-max overflow-clip whitespace-nowrap text-ellipsis'>
                {props.index + 1}. {props.data.title}
            </div>
            <div className='w-min '>
                {props.type === 'homepage' && (
                    <div className='w-auto flex justify-between items-center gap-3'>
                        <span className={`font-medium font-manrope text-base uppercase 
                        ${props.data.difficulty === 'easy' ? 'text-[#2EA6A3]' : (props.data.difficulty === 'medium' ? 'text-[#FBDD8D]' : 'text-[#FF8E96]')}`}>
                            {props.data.difficulty.split('')[0]}
                        </span>
                        <button
                        onClick={()=>props.questionType==='coding'?navigate(`/viewcq/${props.data.id}`):navigate(`/viewmcq/${props.data.id}`)}  
                        className='text-highlightText text-xs rounded-full w-max py-1 px-[0.65rem] border border-highlightText gap-1 flex items-center'>
                            View More
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
                                <path d="M5.3799 8C5.26845 8 5.15699 7.95894 5.069 7.87094C4.89888 7.70082 4.89888 7.41925 5.069 7.24913L8.31886 3.99926L5.069 0.749406C4.89888 0.579286 4.89888 0.297709 5.069 0.12759C5.23912 -0.0425298 5.52069 -0.0425298 5.69081 0.12759L9.25159 3.68835C9.42171 3.85847 9.42171 4.14005 9.25159 4.31017L5.69081 7.87094C5.60282 7.95894 5.49136 8 5.3799 8Z" fill="#2575FC" />
                                <path d="M9.00304 4.44662H0.384089C0.17412 4.44662 0 4.22264 0 3.95256C0 3.68247 0.17412 3.4585 0.384089 3.4585H9.00304C9.21301 3.4585 9.38713 3.68247 9.38713 3.95256C9.38713 4.22264 9.21301 4.44662 9.00304 4.44662Z"
                                    fill="currentColor" />
                            </svg>
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default QuestionListing
