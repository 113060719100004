import React, { useEffect } from 'react'
import { PrimaryButton } from './Buttons'


type modalProps = {
    open: boolean,
    setOpen: any,
    onClick: Function,
    text:string
}
const Modal = (props: modalProps) => {
    const { open, setOpen, onClick,text } = props

    const onClickModal=()=>{
        const body = document.body;
        body.style.removeProperty('overflow');
        setOpen(false)
        onClick()
    }
    useEffect(() => {
        const body = document.body;
        window.scrollTo(0,0)
        if (!open) {
            body.style.removeProperty('overflow');
        }
        else {
            body.style.overflow = "hidden";
        }
    }, [open])
    return (
        <div className={`w-full absolute top-0 left-0 flex justify-center items-center backdrop-blur-md ${open?'h-full overflow-hidden':'h-0 overflow-hidden'}`}>
            <div className={`bg-white rounded-lg w-96 h-72 shadow-md flex flex-col items-center justify-end p-10`}>
                <span className='my-10 text-darkBlue font-montserrat font-semibold text-lg'>
                    {text}
                </span>
                <PrimaryButton onClick={onClickModal}>
                    continue
                </PrimaryButton>
            </div>
        </div>
    )
}

export default Modal
