import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import DropDown from '../components/DropDown'
import { PrimaryButton, SecondaryButtons } from '../components/Buttons'
import { postRequest } from '../ApiFunctions.tsx/post'
import Modal from '../components/Modal'

const TestCaseHandler = () => {
    const { id } = useParams()
    const navigate=useNavigate()
    const [type, setType] = useState<string>('basic')
    const [description, setDescription] = useState<string>('')
    const [sample, setSample] = useState<boolean>(false)
    const [inputs, setInputs] = useState<string>('')
    const addQuestion=window.location.href.includes('addquestion')
    const [success, setSuccess] = useState<any[]>([])
    const [failed, setFailed] = useState<any[]>([])
    const [open,setOpen]=useState<boolean>(false)
    const [resRecieved, setResRecieved] = useState<boolean>(false)

    const deleteTestCase = () => {
        return;
    }
    const types = [
        'basic',
        'advance',
        'edge case'
    ]

    const addTestCase = () => {


        const testData = {
            inputs: [
                {
                    desc: description,
                    data: btoa(inputs),
                    sample,
                    type
                }
            ]
        }

        postRequest(`${process.env.REACT_APP_API}problem/${id}/checktestcases`, testData, (data: any) => {
            setResRecieved(true)
            data.map((data: any) => {
                if (data.status === 'Accepted') {
                    setSuccess((successful) => [data, ...successful])
                }
                else {
                    setFailed((successful) => [data, ...successful])

                }
            })
        }, (err: any) => {
            console.log(err)
        })
    }


    const confirmAddTestCase=()=>{
        const testData = {
            testcases: success
        }
       
        postRequest(`${process.env.REACT_APP_API}problem/${id}/addtestcases`, testData, (data: any) => {
           setOpen(true)
        }, (err: any) => {
            console.log(err)
        })
    }

    return (
        <div className={`bg-gradient-to-br  from-white to-[#DFEAFD] min-h-screen w-full h-auto py-8 px-24 `}>
            {resRecieved ? <>
                <div className='bg-white rounded-lg w-full min-h-[80vh] p-3'>
                    <div className='mb-10'>

                        <span className='mx-auto text-darkBlue border-b-2 border-highlightText p-2 font-montserrat font-semibold text-2xl'>
                            Test Case results
                        </span>
                    </div>
                    {success.map((success) => {
                        return <div className=' bg-gradient-to-br from-[#C6EAD5] to-[#c6ead530] w-full rounded-lg p-3 shadow-sm '>
                            <div className='flex justify-between h-auto py-3 items-center'>
                                <div className='text-darkBlue capitalize text-xl font-semibold font-montserrat'>
                                    {success.desc}
                                </div>
                                <div className='w-fit rounded-full bg-white border border-highlightText text-highlightText font-poppins text-sm py-1 px-3'>
                                    {success.type}
                                </div>
                            </div>
                            <div className='my-2 w-full h-auto p-2 rounded-md bg-[#ffffff]'>
                                <span className='text-darkBlue font-montserrat text-base font-medium'>
                                    Input
                                </span>
                                <p className='text-textBlack font-manrope text-base '>
                                    {atob(success.input)}
                                </p>
                            </div>
                            <div className='my-2 w-full h-auto p-2 rounded-md bg-[#ffffff]'>
                                <span className='text-darkBlue font-montserrat text-base font-medium'>
                                    Output
                                </span>
                                <p className='text-textBlack font-manrope text-base '>
                                    {atob(success.output)}
                                </p>
                            </div>

                            {success.sample && <p className='text-darkBlue font-manrope font-semibold text-sm flex gap-1 items-center'>
                                <div className='h-4 w-4 rounded-[4px] border border-inputBorder bg-highlightText flex justify-center items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="0.75rem" height="0.75rem"
                                        viewBox="0 0 14 11" fill="none">
                                        <path d="M13.75 1.24988L4.75 10.2499L0.625 6.12488L1.6825 5.06738L4.75 8.12738L12.6925 0.192383L13.75 1.24988Z"
                                            fill="white" />
                                    </svg>
                                </div>
                                Included in sample
                            </p>}
                        </div>
                    })}
                    {success.length > 0 && (
                        <div className='w-fit mx-auto flex gap-3 mt-4'>
                            <SecondaryButtons onClick={() => {
                                setResRecieved(false)
                                setFailed([])
                            }}>
                                Back
                            </SecondaryButtons>
                            <PrimaryButton onClick={confirmAddTestCase}>
                                Add successful test Cases
                            </PrimaryButton>
                        </div>
                    )}
                    {failed.map((failed) => {
                        return <div className=' bg-gradient-to-br from-[#EDCFD2] to-[#EDCFD230] w-full rounded-lg p-3 shadow-sm '>
                            <div className='text-2xl w-full h-auto text-darkBlue font-montserrat font-semibold'>
                                {failed.status}
                            </div>
                            <div className='flex justify-between h-auto py-3 items-center'>
                                <div className='text-darkBlue capitalize text-xl  font-montserrat font-medium'>
                                    {failed.desc}
                                </div>
                                <div className='w-fit rounded-full bg-white border border-highlightText text-highlightText font-poppins text-sm py-1 px-3'>
                                    {failed.type}
                                </div>
                            </div>
                            <div className='my-2 w-full h-auto p-2 rounded-md bg-[#ebebeb]'>
                                <span className='text-darkBlue font-montserrat text-base font-medium'>
                                    Input
                                </span>
                                <p className='text-textBlack font-manrope text-base '>
                                    {atob(failed.input)}
                                </p>
                            </div>
                            <div className='my-2 w-full h-auto p-2 rounded-md bg-[#ebebeb]'>
                                <span className='text-darkBlue font-montserrat text-base font-medium'>
                                    Output
                                </span>
                                <p className='text-textBlack font-manrope text-base '>
                                    {atob(failed.output)}
                                </p>
                            </div>

                            {failed.sample && <p className='text-darkBlue font-manrope font-semibold text-sm flex gap-1 items-center'>
                                <div className='h-4 w-4 rounded-[4px] border border-inputBorder bg-highlightText flex justify-center items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="0.75rem" height="0.75rem"
                                        viewBox="0 0 14 11" fill="none">
                                        <path d="M13.75 1.24988L4.75 10.2499L0.625 6.12488L1.6825 5.06738L4.75 8.12738L12.6925 0.192383L13.75 1.24988Z"
                                            fill="white" />
                                    </svg>
                                </div>
                                Included in sample
                            </p>}
                        </div>
                    })}
                    {success.length === 0 && (
                        <div className='w-fit mx-auto mt-4'>
                        <PrimaryButton  onClick={() => {
                            setResRecieved(false)
                            setFailed([])
                        }}>
                            Done
                        </PrimaryButton>
                        </div>
                    )}
                </div>

            </> : <>
                <div className='w-full flex justify-center items-center'>

                    <span className='mx-auto text-darkBlue border-b-2 border-highlightText p-2 font-montserrat font-semibold text-2xl'>
                        Add Test Case
                    </span>
                </div>
                {/* <div className='w-full flex justify-end'>
                    <SecondaryButtons classes='flex gap-1' onClick={deleteTestCase}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M15.1464 4.35355L15.2929 4.5H15.5H18.5V5.5H5.5V4.5H8.5H8.70711L8.85355 4.35355L9.70711 3.5H14.2929L15.1464 4.35355ZM8 20.5C7.17614 20.5 6.5 19.8239 6.5 19V7.5H17.5V19C17.5 19.8239 16.8239 20.5 16 20.5H8Z"
                                stroke="#2575FC" />
                        </svg>
                        Delete
                    </SecondaryButtons>
                </div> */}
                <div className='w-full h-auto flex gap-8 mt-20'>
                    <div className='w-full h-auto flex flex-col gap-7'>
                        <span className='font-manrope text-2xl text-darkBlue font-semibold'>
                            Description
                        </span>
                        <textarea
                            onChange={(e: any) => setDescription(e.target.value)}
                            value={description}
                            placeholder='Enter description' className='bg-gradient-to-br mt-8 from-[#ffffff] via-white to-[#DFEAFD]  
                    resize-none h-[60vh] p-4 shadow-sm rounded-lg focus:outline-none  text-base text-textGrey'/>
                    </div>
                    <div className='w-full h-auto flex flex-col gap-7'>
                        <div className='flex justify-between items-center'>
                            <span className='font-manrope text-2xl text-darkBlue font-semibold'>
                                Input
                            </span>
                            <DropDown onComponentClick={(data: any) => setType(data)} editable={true} displayText={`Type : ${type.toUpperCase()}`} array={types} />
                        </div>
                        <textarea
                            onChange={(e: any) => setInputs(e.target.value)}
                            value={inputs}
                            placeholder='Enter Test Case input' className='bg-gradient-to-br from-[#ffffff] via-white to-[#DFEAFD] 
                    resize-none h-[60vh] p-4 shadow-sm rounded-lg focus:outline-none  text-base text-textGrey'/>
                    </div>
                </div>


                <div className='w-full mt-8'>
                    <div className='gap-2 flex items-center'>
                        <div
                            onClick={() => setSample(!sample)}
                            className={`rounded-[4px] h-4 w-4 border flex justify-center items-center border-inputBorder ${sample && 'bg-highlightText'}`}>
                            {sample && <svg xmlns="http://www.w3.org/2000/svg"
                                width="0.75rem" height="0.75rem"
                                viewBox="0 0 14 11" fill="none">
                                <path d="M13.75 1.24988L4.75 10.2499L0.625 6.12488L1.6825 5.06738L4.75 8.12738L12.6925 0.192383L13.75 1.24988Z"
                                    fill="white" />
                            </svg>}
                        </div>
                        <span className='text-lg font-poppins text-darkBlue'>
                            Include in sample
                        </span>
                    </div>
                </div>


                <div className='w-full flex justify-center items-center'>
                    <PrimaryButton onClick={addTestCase}>
                        Submit
                    </PrimaryButton>
                </div>

            </>
            }
            <Modal open={open} setOpen={setOpen} onClick={()=>{navigate(`/viewcq/${id}`)}} text={'testcases added succesfully'}/>
        </div>
    )
}

export default TestCaseHandler
