import { useEffect } from 'react'
import Heading from '../components/Heading'
import { PrimaryButton, SecondaryButtons } from '../components/Buttons'
import HomePageListing from '../components/examPortal/HomePageListing'
import { postRequest } from '../ApiFunctions.tsx/post'
import { useNavigate } from 'react-router-dom'
import { useUserData } from '../components/useUserData'

const HomePage = () => {
    const navigate = useNavigate()
    const { userName } = useUserData()
    useEffect(() => {
        !document.cookie.includes('lgdin') && navigate('/login')
    }, [])
    return (
        <div className={`bg-gradient-to-br from-white to-whitesky min-h-screen w-full h-auto pt-28 px-24 pb-10`}>
            <div className='flex w-full h-auto justify-between items-center'>
                <div className=' pr-48 pb-6 border-b border-bleachBlue'>
                    <Heading text={`welcome ${userName},`} />
                </div>
                <PrimaryButton classes='flex gap-[6px]' onClick={() => { navigate('/create-exam') }} >
                    create exam
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <path d="M10.416 7.81258C10.416 7.67445 10.4709 7.54197 10.5686 7.4443C10.6662 7.34662 10.7987 7.29175 10.9368 7.29175H15.1035C15.2416 7.29175 15.3741 7.34662 15.4718 7.4443C15.5695 7.54197 15.6243 7.67445 15.6243 7.81258C15.6243 7.95071 15.5695 8.08319 15.4718 8.18087C15.3741 8.27854 15.2416 8.33341 15.1035 8.33341H10.9368C10.7987 8.33341 10.6662 8.27854 10.5686 8.18087C10.4709 8.08319 10.416 7.95071 10.416 7.81258ZM10.9368 9.37508C10.7987 9.37508 10.6662 9.42995 10.5686 9.52763C10.4709 9.6253 10.416 9.75778 10.416 9.89591C10.416 10.034 10.4709 10.1665 10.5686 10.2642C10.6662 10.3619 10.7987 10.4167 10.9368 10.4167H15.1035C15.2416 10.4167 15.3741 10.3619 15.4718 10.2642C15.5695 10.1665 15.6243 10.034 15.6243 9.89591C15.6243 9.75778 15.5695 9.6253 15.4718 9.52763C15.3741 9.42995 15.2416 9.37508 15.1035 9.37508H10.9368ZM10.416 14.5834C10.416 14.4453 10.4709 14.3128 10.5686 14.2151C10.6662 14.1175 10.7987 14.0626 10.9368 14.0626H15.1035C15.2416 14.0626 15.3741 14.1175 15.4718 14.2151C15.5695 14.3128 15.6243 14.4453 15.6243 14.5834C15.6243 14.7215 15.5695 14.854 15.4718 14.9517C15.3741 15.0494 15.2416 15.1042 15.1035 15.1042H10.9368C10.7987 15.1042 10.6662 15.0494 10.5686 14.9517C10.4709 14.854 10.416 14.7215 10.416 14.5834ZM10.9368 16.1459C10.7987 16.1459 10.6662 16.2008 10.5686 16.2985C10.4709 16.3961 10.416 16.5286 10.416 16.6667C10.416 16.8049 10.4709 16.9374 10.5686 17.035C10.6662 17.1327 10.7987 17.1876 10.9368 17.1876H15.1035C15.2416 17.1876 15.3741 17.1327 15.4718 17.035C15.5695 16.9374 15.6243 16.8049 15.6243 16.6667C15.6243 16.5286 15.5695 16.3961 15.4718 16.2985C15.3741 16.2008 15.2416 16.1459 15.1035 16.1459H10.9368Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.20898 14.0626C5.20898 13.9244 5.26386 13.792 5.36153 13.6943C5.45921 13.5966 5.59168 13.5417 5.72982 13.5417H8.33398C8.47212 13.5417 8.60459 13.5966 8.70227 13.6943C8.79994 13.792 8.85482 13.9244 8.85482 14.0626V16.6667C8.85482 16.8049 8.79994 16.9374 8.70227 17.035C8.60459 17.1327 8.47212 17.1876 8.33398 17.1876H5.72982C5.59168 17.1876 5.45921 17.1327 5.36153 17.035C5.26386 16.9374 5.20898 16.8049 5.20898 16.6667V14.0626ZM6.25065 14.5834V16.1459H7.81315V14.5834H6.25065Z" fill="white" />
                        <path d="M9.22251 8.18076C9.31738 8.08253 9.36988 7.95096 9.36869 7.8144C9.3675 7.67784 9.31273 7.54721 9.21616 7.45064C9.11959 7.35408 8.98896 7.2993 8.8524 7.29812C8.71584 7.29693 8.58428 7.34943 8.48605 7.4443L6.77094 9.1594L6.09751 8.48597C5.99928 8.39109 5.86771 8.33859 5.73115 8.33978C5.59459 8.34097 5.46396 8.39574 5.36739 8.49231C5.27083 8.58888 5.21605 8.71951 5.21486 8.85607C5.21368 8.99263 5.26617 9.12419 5.36105 9.22242L6.77094 10.6323L9.22251 8.18076Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.20833 3.125C4.6558 3.125 4.12589 3.34449 3.73519 3.73519C3.34449 4.12589 3.125 4.6558 3.125 5.20833V19.7917C3.125 20.3442 3.34449 20.8741 3.73519 21.2648C4.12589 21.6555 4.6558 21.875 5.20833 21.875H15.625C16.1775 21.875 16.7074 21.6555 17.0981 21.2648C17.4888 20.8741 17.7083 20.3442 17.7083 19.7917V5.20833C17.7083 4.6558 17.4888 4.12589 17.0981 3.73519C16.7074 3.34449 16.1775 3.125 15.625 3.125H5.20833ZM4.16667 5.20833C4.16667 4.93207 4.27641 4.66711 4.47176 4.47176C4.66711 4.27641 4.93207 4.16667 5.20833 4.16667H15.625C15.9013 4.16667 16.1662 4.27641 16.3616 4.47176C16.5569 4.66711 16.6667 4.93207 16.6667 5.20833V19.7917C16.6667 20.0679 16.5569 20.3329 16.3616 20.5282C16.1662 20.7236 15.9013 20.8333 15.625 20.8333H5.20833C4.93207 20.8333 4.66711 20.7236 4.47176 20.5282C4.27641 20.3329 4.16667 20.0679 4.16667 19.7917V5.20833ZM18.75 8.33333C18.75 7.91893 18.9146 7.5215 19.2076 7.22848C19.5007 6.93545 19.8981 6.77083 20.3125 6.77083C20.7269 6.77083 21.1243 6.93545 21.4174 7.22848C21.7104 7.5215 21.875 7.91893 21.875 8.33333V18.9078L20.3125 21.2516L18.75 18.9078V8.33333ZM20.3125 7.8125C20.1744 7.8125 20.0419 7.86737 19.9442 7.96505C19.8465 8.06272 19.7917 8.1952 19.7917 8.33333V9.375H20.8333V8.33333C20.8333 8.1952 20.7785 8.06272 20.6808 7.96505C20.5831 7.86737 20.4506 7.8125 20.3125 7.8125ZM20.3125 19.3734L19.7917 18.5922V10.4167H20.8333V18.5922L20.3125 19.3734Z" fill="white" />
                    </svg>
                </PrimaryButton>
            </div>
            <p className='my-11 text-textGrey font-montserrat tracking-normal text-[22px] font-medium'>
                What would you like to enter ?
            </p>
            <div className='flex w-full justify-between items-center '>
                <div className='gap-5 flex items-center'>
                    <SecondaryButtons onClick={() => { navigate('/codingquestion') }}>
                        Coding Question
                    </SecondaryButtons>
                    <span className='text-textGrey text-base font-medium '>
                        OR
                    </span>
                    <SecondaryButtons onClick={() => { navigate('/mcq') }}>
                        MCQ
                    </SecondaryButtons>
                </div>
                <SecondaryButtons onClick={() => { navigate('/view-exam') }}>
                    View All Exams
                </SecondaryButtons>
            </div>
            <HomePageListing />
        </div>
    )
}

export default HomePage
